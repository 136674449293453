import { Button, Checkbox, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core'
import React,{useEffect, useState} from 'react'
import { getProduitFormules, getProduitAll } from 'redux/actions/apiActions'
import {getproduitBranche,UserBranche,addProduitBranche} from 'redux/actions/settingsAction'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CustumModal from 'components/CustumModal';
import swal from 'sweetalert';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import { deleteProduitBranche } from 'redux/actions/settingsAction';
import { changeStateProduitBranche } from 'redux/actions/settingsAction';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        padding:10,
    },
  },
};

const ProduitBranche = ({branche}) => {

    const [brproduits,setBrproduits] = useState([])
    const [loading,setLoading] = useState(false)
    const [inputField,setInputField] = useState({
        codeproduit:"",
        codeformule:[],
        codereseau: branche?.id
    })

    const handleProduitPranche = async ()=>{
       const res = await getproduitBranche(branche)
       if(res)
            setBrproduits(res)
    }
    useEffect(()=>{
        handleProduitPranche()
    },[])
    const [open,setOpen] = useState(false)
    const handleOpen =()=>{
        setOpen(true)
    }
    const handleClose =(e,reason)=>{
        if (reason && reason == "backdropClick") {
            return;
          }
        setOpen(false)
    }
    const [produits, setProduits] = React.useState([])
    const [formules, setFormules] = React.useState([])

    useEffect(() => {
        getProduitAll().then(data => { if (data) { setProduits(data); } })
    }, [])
    const handleFormuleProduit = () => {
        if (inputField['codeproduit'] !== "") {
            getProduitFormules(inputField.codeproduit).then(data => { if (data) { 
                setFormules(data);
                setInputField({...inputField,'codeformule':[]})
            } })
        }
    }
    useEffect(() => {
        handleFormuleProduit()
    }, [inputField.codeproduit])
    const handleChange = (event) => {
        const { name, value } = event.target
        setInputField({
            ...inputField,
            [name]: value
        })
    }
  
    const handleChangeFormule = (event) => {
        const {
          target: { value },
        } = event;
        setInputField(
          {...inputField,['codeformule']:typeof value === 'string' ? value.split(',') : value}
        );
      };

    const handleSaveProduitReseau = () => {
        if (inputField.codeproduit === '') return
        if (inputField.codebranche === '') return
        if (inputField.codeformule && inputField.codeformule.length===0){swal("Warning","Aucune formule selectionné",'warning')}
        setLoading(true)

        addProduitBranche(inputField).then(data => {
            if (data) {
                getproduitBranche(branche.id).then(data => { if (data) { setBrproduits(data); } })
                swal("Succes","Enregistrement effectué avec succès",'success')
                setLoading(false)
            } else {
                setLoading(false)
                swal("Erreur","Erreur lors de l'enregistrement",'error')
                return false
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
            swal("Erreur","Erreur lors de l'enregistrement",'error')
            return
        })
    }

    const handleDeleteProduit =(id)=>{
        if (id) {
            deleteProduitBranche(id).then(res => {
                if (res) {
                    getproduitBranche(branche.id).then(data => { if (data) { setBrproduits(data); } })
                    swal("Succes","Suppression effectué avec succès",'success')
                } else {
                    setLoading(false)
                    swal("Erreur","Erreur lors de la suppression",'error')
                    return false
                }
            }).catch(err => {
                console.log(err);
                swal("Erreur","Erreur lors de la suppression",'error')
                return
            })
        }

    }
    const handleChangeState =(id)=>{
        if (id) {
            changeStateProduitBranche({ id }).then(res => {
                if (res) {
                    getproduitBranche(branche.id).then(data => { if (data) { setBrproduits(data); } })
                    swal("Succes","Modification effectuée avec succès",'success')
                } else {
                    setLoading(false)
                    swal("Erreur","Erreur lors de la modification",'error')
                    return false
                }
            }).catch(err => {
                console.log(err);
                swal("Erreur","Erreur lors de la modification",'error')
                return
            })
        }
    }
  return (
    <div>
        <Paper variant='outlined' className='bg-slate-100 flex flex-row py-4 justify-center max-w items-center max-w-screen-sm mx-auto my-4 border-dashed'>
            {!(brproduits && brproduits.length!==0)&& <span>Aucun produit associé</span>}
            <Button onClick={handleOpen} className='hover:bg-primary-main hover:text-white rounded-full shover:shadow-md mx-3 space-x-2'><AddCircleOutlineIcon />Ajouter un produit</Button>
        </Paper>
        <CustumModal open={open} handleOpen={handleOpen} handleClose={handleClose} title="Associer un produit à la branche" width='sm' actions={<div className='py-2'>
        <Button className='bg-primary-main px-4 text-white hover:bg-secondary-main border-0 shadow-md rounded-full'>Erengistrer</Button>
        </div>}>
            <div>
                <TextField
                    variant="outlined"
                    id="codeproduit"
                    name='codeproduit'
                    select
                    label="Produit"
                    value={inputField['codeproduit']}
                    onChange={handleChange}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ marginTop: 20, marginBottom: 10 }}
                >
                    <MenuItem value='' />
                    {(produits.length !== 0) && produits.map((option) => (
                        <MenuItem key={option.id} value={option.CodeProduit}>
                            {option.MonLibelle}
                        </MenuItem>
                    ))}
                </TextField>
                <FormControl style={{ marginTop: 20, marginBottom: 10,width:'100%' }} >
                <InputLabel variant='outlined' id="label-codeformule">Formule</InputLabel>
                    <Select
                            labelId="label-codeformule"
                            id="codeformule"
                            multiple
                            value={inputField['codeformule'] || []}
                            onChange={handleChangeFormule}
                            label='Formules'
                            renderValue={(selected) => selected.join(', ')}
                            variant="outlined"
                            name='codeformule'
                            MenuProps={MenuProps}
                    >
                        <MenuItem value='' />
                        {(formules.length !== 0) && formules.map((option) => (
                            <MenuItem key={option.CodeProduitFormule} value={option.CodeProduitFormule}>
                                <Checkbox checked={inputField.codeformule?.indexOf(option.CodeProduitFormule) > -1} />
                                <ListItemText primary={option.MonLibelle} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </CustumModal>
        
        <div className='m-2'>
        {(brproduits && brproduits.length!==0) && (
            <MaterialTable
                columns={[
                    { title: '#', field: 'id' },
                    { title: 'Code produit', field: 'codeproduit' },
                    { title: 'Produit', field: 'libelleproduit' },
                    { title: 'Code version', field: 'codeproduitformule' },
                    { title: 'Formule', field: 'libelleformule' },
                    { title: 'Etat', field: 'estactif', render: rowData => (rowData.estactif === 1 ? "Actif" : "Bloquer") },
                    {title:'Actions',render:rowData=>{return (
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                                <IconButton aria-label="Supprimer le produit" onClick ={()=>handleDeleteProduit(rowData.id)}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton aria-label={rowData.estactif === 1 ? 'Bloquer le produit' : "Activer le produit"} onClick ={(event, rowData) => handleChangeState(rowData.id)}>
                            {rowData.estactif === 1 ? <HighlightOffIcon /> : <CheckIcon />}
                            </IconButton>
                        </div>
                    )}}
                ]}
                data={brproduits}
                title="Produit associées"
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Rechercher',
                        searchTooltip: 'Rechercher'
                    },
                    body: {
                        emptyDataSourceMessage: 'Aucune donnée trouvée !'
                    },
                    pagination: {
                        labelRowsSelect: 'Ligne(s)',
                        labelDisplayedRows: '{count} sur {from}-{to}',
                        firstTooltip: 'Prémière page',
                        previousTooltip: 'Précédent',
                        nextTooltip: 'Suivant',
                        lastTooltip: 'Dernière page'
                    }
                }}

                options={{
                    exportButton: true,
                    searchFieldStyle: {
                        fontSize: 18,
                        width: '100%',
                        height: 50,
                    },
                    actionsColumnIndex: -1
                }}
            />)}
        </div>
    </div>
  )
}

export default ProduitBranche