import React, {useCallback, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { reduxForm, change, getFormValues, registerField,reset,Field,initialize } from 'redux-form';
import { connect,useDispatch} from 'react-redux'
import { Checkbox, Divider, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import Simulateur from "../simulateur";
import CustomDate from 'components/CustomDate/CustomDate'
import dayjs from 'dayjs'
import CustomSlider from 'pages/Banking/Prets/addprets/CustomSlider'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import { evaluationPret, formatNumber } from 'application'
import { ageAssure } from 'redux/actions/userActions'
import { getTauxInteret } from 'redux/actions/apiActions'
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Paper,Tooltip,IconButton,Typography,Button } from '@material-ui/core'
import ModalLoading from 'components/ModalLoading';
import { green, red } from '@material-ui/core/colors';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReportIcon from '@material-ui/icons/Report';
const useStyles = makeStyles((theme)=>({
    root:{
        width:'85%',
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:75
      },

      boxMain:{
        display:'flex',
        justifyContent:'space-between'
      },
      hPaper:{
        padding:10,
        marginBottom:20,
        borderLeftColor: theme.palette.primary.main,
        borderLeftWidth:8,
        display:'flex',
        justifyContent:'space-between'
      },
      hContainer:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
      },
      bodycontainer:{
        display:'flex',
        marginBottom:20

      },
      simForm:{
        display:'flex',
        flexDirection:'column',
        padding:60,
        width:'60%',
        marginLeft:'auto',
        marginRight:'auto',
      },
      simResult:{
        display:'flex',
        flexDirection:'column',
        flex:1,
        marginLeft:20,
        padding:20
      },
      errorsCard:{
        padding:20,
        flex:'1',
        margin:20,
        borderRadius:10,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:red[100]
      },
      errors:{
        fontWeight:'bold',
        color:'#ff0000',
        fontSize:'14px',
      },
      btnEvaluer:{
        display:'flex',
        flex:1,
        padding:10,
        '&:hover':{
            backgroundColor:green[400],
            color:'#ffffff'
        }
      },
      goBouton:{
        flex:1,
        backgroundColor:green[400],
        paddingLeft:20,
        paddingRight:20,
        color:'white',
        alignSelf:'center',
        paddingTop:10,
        paddingBottom:10,
        '&:hover':{
            color:green[400],
        }
      }
}))

function PretSimulation(props) {
        const {user: { credentials },handleCliqueFonction,data,ui:{layoutRoot} } = props
        const history = useHistory()
        const classes = useStyles()
        const [autocalcul,setAutocalcul] = React.useState(true)
        const [errors,setErrors] = React.useState([])
        const [loading,setLoading] =useState(false)
 

        const handleNewCotation = () => {
            history.push(`/${layoutRoot}/epret/add-pret`, {fonction:'ADD'});
        }

        const resetForm =()=>{
            props.dispatch(initialize('addPret',{}))

            if(data && !data.hasOwnProperty('typeadherent')){
                props.dispatch(registerField("addPret",'typeadherent','Field'));
            }
            props.updateField('typeadherent','Individuel')   

            history.push(`/${layoutRoot}/epret`, {fonction:'SIMUL'});
        }

        const handleIsCotable = useCallback(()=>{
            if(data && (data.datenaissance && data.datenaissance!=='') && (data.montantpret && data.montantpret!=='') &&  (data.duree && data.duree!=='') && (data.typepret && data.typepret!=='') && (data.remboursement && data.remboursement!=='') && (data.montantobseques && data.montantobseques!=='') && (data.primeht && data.primeht!=='')){
                if(!data.hasOwnProperty('iscotable')){
                    props.dispatch(registerField("addPret",'iscotable','Field'));
                }
                props.updateField('iscotable',true)
            }
        },[data?.datenaissance,data?.montantpret,data?.duree,data?.typepret,data?.remboursement,data?.montantobseques,data?.primeht,data?.fraisaccessoire])
        
        useEffect(()=>{
            handleIsCotable()
        },[data?.datenaissance,data?.montantpret,data?.duree,data?.typepret,data?.remboursement,data?.montantobseques,data?.primeht,data?.fraisaccessoire])
        
        useEffect(()=>{
            if (data && (data.datenaissance && data.datenaissance !== '') && (data.montantpret && data.montantpret !== '') && (data.duree && data.duree!=="" && data.duree!==0)   && ((data.typepret && data.typepret !== '') ||  (data.remboursement &&data.remboursement !== ''))) {
                if(data && !data.hasOwnProperty('iscotable')){
                    props.dispatch(registerField("addPret",'iscotable','Field'));
                }
                props.updateField('isvalide',true)
            }
        },[data?.datenaissance,data?.montantpret,data?.duree,data?.typepret,data?.remboursement])
        
        useEffect(() => {
            if(['WF','092','PAMFCI'].includes(credentials.codepartenaire) && data && data.typepret==='decouvert'){
                if(data && data.hasOwnProperty('iscotable') && data.iscotable){
                    props.dispatch(registerField("addPret",'remboursement','Field'));
                }
                props.updateField('remboursement','Unique')
            }
        })

        useEffect(()=>{
            if(['WF','092','PAMFCI'].includes(credentials.codepartenaire)){
                if(data && !data.hasOwnProperty('iscotable')){
                    props.dispatch(registerField("addPret",'periodicite','Field'));
                }
                props.updateField('periodicite',data?.remboursement)
            }
        },[data?.remboursement])

        useEffect(()=>{
            if(['PAMFCI'].includes(credentials.codepartenaire)){
                if(data && !data.hasOwnProperty('iscotable')){
                    props.dispatch(registerField("addPret",'periodicite','Field'));
                }
                props.updateField('periodicite','Unique')
            }

            if(['PAMFCI'].includes(credentials.codepartenaire)){
                if(data && !data.hasOwnProperty('iscotable')){
                    props.dispatch(registerField("addPret",'periodicite','Field'));
                }
                props.updateField('remboursement','Unique')
            }

            if(data && data.typeadherent =='Groupe'){
                if(data.hasOwnProperty('adherentconex')){
                    props.dispatch(registerField("addPret",'adherentconex','Field'));
                }
                props.updateField('adherentconex',[])
            }
            if(data && !data.hasOwnProperty('typeadherent')){
                props.dispatch(registerField("addPret",'typeadherent','Field'));
            }
            props.updateField('typeadherent','Individuel')   
        },[])
    
        const handleCaculpret = async (e) => {
            let currentErros =[]
            e&&e.preventDefault()
            setLoading(true)
            
            if(data && data.hasOwnProperty('duree') && (data?.duree ==='' || data?.duree===0)){
                currentErros.push("Durré du prêt incorrect")
            }
            if(data && data.hasOwnProperty('montantpret') && (data.montantpret ==='' || data.montantpret===0)){
                currentErros.push("Montant du prêt incorrect")
            }
            if(data && data.hasOwnProperty('datenaissance') && (data.datenaissance ==='' || !dayjs(data.datenaissance).isValid())){
                currentErros.push("Date de naissance de l'emprunteur prêt incorrect")
            }
            if(data && data.hasOwnProperty('remboursement') && data.remboursement ===''){
                currentErros.push("Périodicité de rembourssement incorrecte")
            }
            if(data && data.hasOwnProperty('periodicite') && data.periodicite ===''){
                currentErros.push("Périodicité de paiement de la prime incorrecte")
            }

            if(currentErros.length!==0){
                setErrors([...currentErros])
                return
            }
            if(!data.hasOwnProperty('examensup')){
                props.dispatch(registerField("addPret",'examensup','Field'));
            }
            props.updateField('examensup',false)
             
            if(!data.hasOwnProperty('motifcotation')){
                props.dispatch(registerField("addPret",'motifcotation','Field'));
            }
            props.updateField('motifcotation',[])

            if(credentials.codepartenaire !=='PAMFCI'){
                const p = await evaluationPret(ageAssure(data.datenaissance), 1000000, Number(data.duree.replace(/ /g, '')), credentials.codepartenaire,data.periodicite)
                if(!data.hasOwnProperty('montantobseques')){
                    props.dispatch(registerField("addPret",'montantobseques','Field'));
                }
                props.updateField('montantobseques',Math.round(Number(p)))
            }else{
                if(!data.hasOwnProperty('montantobseques')){
                    props.dispatch(registerField("addPret",'montantobseques','Field'));
                }
                if(data.hasOwnProperty('unableyako') && data.unableyako ==true){
                    props.updateField('montantobseques',0)
                }else{
                    props.updateField('montantobseques',5000)
                }
                
            }
            

            if(!data.hasOwnProperty('capital')){
                props.dispatch(registerField("addPret",'capital','Field'));
            }
            if(data.hasOwnProperty('unableyako') && data.unableyako ==true){
                props.updateField('capital',0)
            }else{
                props.updateField('capital',1000000)
            }

            const dureeCalcul = credentials.codepartenaire === '092' ? 84 : (credentials.codepartenaire === 'PAMFCI' ? data.duree :99)
            let maperiodicite = 'All' 
            maperiodicite = (credentials.codepartenaire === 'WF') ? (data.remboursement !=='Unique' ? 'All':data.remboursement): data.periodicite 
            maperiodicite = (credentials.codepartenaire === '092') ? (data.periodicite ? data.periodicite:data.remboursement): data.periodicite
            const params = {
                age: ageAssure(data.datenaissance),
                duree: dureeCalcul,
                periodicite: maperiodicite,
                partenaire: credentials.codepartenaire,
                typePret:  data.typepret
            }

            let tauxTrouve = await getTauxInteret(params)
           if(tauxTrouve && tauxTrouve.length===0 && credentials.codepartenaire === 'PAMFCI' && data.duree>60){
                if(!data.hasOwnProperty('examensup')){
                    props.dispatch(registerField("addPret",'examensup','Field'));
                }
                props.updateField('examensup',true)
             
                if(!data.hasOwnProperty('motifcotation')){
                    props.dispatch(registerField("addPret",'motifcotation','Field'));
                    props.updateField('motifcotation',["La durée de souscription ne respecte pas les caractéristiques de la convention"])
                }else{
                    props.updateField('motifcotation',["La durée de souscription ne respecte pas les caractéristiques de la convention"])
                } 
            }

            let tauxInteretUnitaire = (tauxTrouve && tauxTrouve.length !== 0) ? (Number(tauxTrouve[0].tauxprimeunique)) : 0
            if (tauxInteretUnitaire && tauxInteretUnitaire !== undefined) {
                if(!data.hasOwnProperty('txprimeunique')){
                    props.dispatch(registerField("addPret",'txprimeunique','Field'));
                }
                props.updateField('txprimeunique',tauxInteretUnitaire)

                const cprimeHt = (parseInt(data.montantpret.replace(/ /g, '')) * (Number(tauxInteretUnitaire) / 100))
                if (cprimeHt) {
                    if(!data.hasOwnProperty('primeht')){
                        props.dispatch(registerField("addPret",'primeht','Field'));
                    }
                    props.updateField('primeht',Math.round(Number(cprimeHt)))
                }
            }else{
                if(!data.hasOwnProperty('primeht')){
                    props.dispatch(registerField("addPret",'primeht','Field'));
                }
                props.updateField('primeht',"")
            }
            //prime de risque 
            if(credentials.codepartenaire == "PAMFCI"){
                if(!data.hasOwnProperty('primerisque')){
                    props.dispatch(registerField("addPret",'primerisque','Field'));
                }
                props.updateField('primerisque',(parseInt(data.montantpret.replace(/ /g, ''))*(0.5/100)))
            }else{
                if(!data.hasOwnProperty('primerisque')){
                    props.dispatch(registerField("addPret",'primerisque','Field'));
                }
                props.updateField('primerisque',0)
            }

            if (credentials.codepartenaire == "WF" || credentials.codepartenaire == "092") {
                if(!data.hasOwnProperty('fraisaccessoire')){
                    props.dispatch(registerField("addPret",'fraisaccessoire','Field'));
                }
                props.updateField('fraisaccessoire',(parseInt(data.montantpret.replace(/ /g, '')) <= 30000000) ? 0 : 0)
            } else {
                if(!data.hasOwnProperty('fraisaccessoire')){
                    props.dispatch(registerField("addPret",'fraisaccessoire','Field'));
                }
                props.updateField('fraisaccessoire',(parseInt(data.montantpret.replace(/ /g, '')) <= 5000000) ? 0 : 0)
            }
            const ageAd = ageAssure(data.datenaissance)
            if ((data.montantpret && parseInt(data.montantpret.toString().replace(/ /g, '')) > 30000000) || (ageAd < 18 || ageAd > 65)) {
                if(!data.hasOwnProperty('isnotconv')){
                    props.dispatch(registerField("addPret",'isnotconv','Field'));
                }
                props.updateField('isnotconv',true)
                setErrors([...errors,"Cet prêt ne respect pas les closes de votre convention"])
            }
            setLoading(false)

            setAutocalcul(false)
            if(!data.hasOwnProperty('evaluationOk')){
                props.dispatch(registerField("addPret",'evaluationOk','Field'));
            }
            props.updateField('evaluationOk',true)
        }
    
        const demarrerSouscription = () => {
            if (data && data.isnotconv && (credentials.codepartenaire === 'WF' || credentials.codepartenaire === '092' || credentials.codepartenaire === 'PAMFCI')) {
                
                props.dispatch(registerField("addPret", 'examensup', 'Field'));
                props.updateField('examensup', true)
            }
            if(data && data.typeadherent =='Groupe'){
                if(!data.hasOwnProperty('adherentconex')){
                    props.dispatch(registerField("addPret",'adherentconex','Field'));
                }
                props.updateField('adherentconex',[])
            }
            handleNewCotation()
        }

        //auto calcul
        useEffect(()=>{
            if(autocalcul && data && data.iscotable){
                handleCaculpret()
            }
        },[autocalcul])
    
        const handleRunCulculate = useCallback(()=>{
            if (data && (data.datenaissance && data.datenaissance !== '') &&  (data.montantpret && data.montantpret !== '') &&   (data.montantpret && data.montantpret !== 0) && (data.duree && data.duree !== '' && data.duree !==0 ) && (data.typepret && data.typepret !== '') && (data.remboursement && data.remboursement !== '') &&  (data.periodicite && data.periodicite !== '') && autocalcul===true){
            
                handleCaculpret()
            }
        },[data?.datenaissance,data?.montantpret,data?.duree,data?.typepret,data?.remboursement])
        useEffect(()=>{
            handleRunCulculate()
        },[data?.datenaissance,data?.montantpret,data?.duree,data?.typepret,data?.remboursement])
    //default nature de prêt
    
    useEffect(()=>{
        if(data && !data.hasOwnProperty('naturepret')){
            props.dispatch(registerField("addPret",'naturepret','Field'));
        }
        props.updateField('naturepret','Nouveau prêt')
    },[])

    const [unableyako,setUnableyako] = useState(data?.unableyako || false)
    const handleChangeCheckBox =()=>{
        if(data && !data.hasOwnProperty('unableyako')){
            props.dispatch(registerField("addPret",'unableyako','Field'));
        }
        props.updateField('unableyako',!unableyako)
        setUnableyako(!unableyako)
        handleCaculpret()
    }
    useEffect(()=>{
        if(data && !data.hasOwnProperty('unableyako')){
            props.dispatch(registerField("addPret",'unableyako','Field'));
        }
        props.updateField('unableyako',false)
    },[])

    return (
        <div className={classes.root}>
           
            {loading&&<ModalLoading myopen={loading} />}
            <Paper className={classes.hPaper} variant="outlined">
                <div style={{display:'flex'}}>
                    <Tooltip title="Retour"> 
                        <IconButton onClick={()=>handleCliqueFonction('HOME')} color='primary'>
                            <ArrowBackIosIcon style={{fontSize:40}} />
                        </IconButton>
                    </Tooltip>
                    <div className={classes.hContainer}>
                        <Typography variant='h5'>SIMULATEUR DE PRÊT</Typography>
                    </div>
                </div>
                <Tooltip title="Réinitialisé"> 
                    <IconButton onClick={resetForm} color='primary'>
                        <RotateLeftIcon style={{fontSize:40}} />
                    </IconButton>
                </Tooltip>
            </Paper>
            {/*<TypeAdherent />*/}
        <form onSubmit={handleCaculpret}>
            <div className={classes.bodycontainer}>
                <Paper  className={classes.simForm}>
                    <Typography variant='h5'>INFORMATIONS SUR LE PRÊT</Typography>
                    <Field
                        required
                        label="Nature du prêt"
                        id="naturepret"
                        name='naturepret'
                        component={CustomRadio}
                        formControlProps={{fullWidth: true}}
                        options={[
                            { "label": "Nouveau prêt", "value": "Nouveau prêt" }, 
                            { "label": "Rachat interne", "value": "Rachat interne"},
                            { "label": "Rachat externe", "value": "Rachat externe"}
                        ]}
                        row
                        autoFocus
                    />
                    <Field
                        required
                        label="Date de naissance de l'emprunteur"
                        id="datenaissance"
                        name="datenaissance"
                        component={CustomDate}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        InputProps={{
                            type: 'date',
                            inputProps: { max: dayjs().add(-12, 'year').format('YYYY-MM-DD'), min: dayjs().add(-74, 'year').format('YYYY-MM-DD') },
                        }}
                        variant="outlined"
                    />

                    <Field
                        required
                        id="montantpret"
                        name="montantpret"
                        formControlProps={{
                            fullWidth: true
                        }}
                        variant="outlined"
                        min={50000}
                        titre="Quel est le montant du prêt ?"
                        max={1000000000}
                        component={CustomSlider}
                    />
                    <Field
                        required
                        id="duree"
                        name='duree'
                        formControlProps={{
                            fullWidth: true
                        }}
                        variant="outlined"
                        min={1}
                        component={CustomSlider}
                        titre="Quelle est la durée du prêt (Mois) ?"
                        max={720}
                    />
                    {(!(['PAMFCI'].includes(credentials.codepartenaire))) &&<Field
                        component={CustomRadio}
                        required
                        label="Périodicite de remboursement *"
                        id="remboursement"
                        name="remboursement"
                        formControlProps={{
                            fullWidth: true
                        }}
                        options={[
                            { "label": "Mensuelle", "value": "Mensuelle" }, 
                            { "label": "Trimestrielle", "value": "Trimestrielle" }, 
                            { "label": "Semestrielle", "value": "Semestrielle" }, 
                            { "label": "Annuelle", "value": "Annuelle" }, 
                            { "label": "Versement unique", "value": "Unique" }]}
                        row
                    />  }
                
                    <Field
                        component={CustomRadio}
                        required
                        label="Type de prêt"
                        id="typepret"
                        name='typepret'
                        formControlProps={{fullWidth: true}}
                        options={['092'].includes(credentials.codepartenaire)?[
                            { "label": "Prêt à la consommation", "value": "consommation" }, 
                            { "label": "Prêt immobilier", "value": "immobilier"}]:[
                                { "label": "Prêt à la consommation", "value": "consommation" }, 
                                { "label": "Avance de trésorerie", "value": "decouvert" },
                                { "label": "Prêt immobilier", "value": "immobilier"}]}
                        row
                    />
                {(!(['WF','PAMFCI'].includes(credentials.codepartenaire))) &&
                    <Field
                        required
                        label="Périodicite de paiement de la prime *"
                        id="periodicite"
                        name="periodicite"
                        component={CustomRadio}
                        formControlProps={{
                            fullWidth: true
                        }}
                        options={[
                            { "label": "Mensuelle", "value": "Mensuelle" }, 
                            { "label": "Trimestrielle", "value": "Trimestrielle" }, 
                            { "label": "Semestrielle", "value": "Semestrielle" }, 
                            { "label": "Annuelle", "value": "Annuelle" }, 
                            { "label": "Versement unique", "value": "Unique" }]}
                        row
                    />}
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={unableyako} onChange={handleChangeCheckBox} name="unableyako" />}
                            label="Desactiver la garntie YAKO"
                        />
                    </FormGroup>
                    
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center', padding:20,margin:20}}>
                        <Button className={classes.btnEvaluer} type='submit' variant='outlined' color='primary'>Evaluer la prime</Button>
                    </div> 
            </Paper>
            {(data && data?.evaluationOk)&&(<Simulateur elevation={0} className={classes.simResult} >
                    <Simulateur.Group  direction='column'>
                        <Typography variant='h5'>RESULTAT DE LA SIMULATION</Typography>
                        <Divider style={{marginTop:20,marginBottom:20}}/>
                        {(errors && errors.length!==0)&&<div className= {classes.errorsCard}><ul className={classes.errors}>
                        {errors.map(error=><li>{error}</li>)}
                    </ul></div>}
                        {(!data.isnotconv) && <Simulateur.Card style={{padding:20}} direction='column' palign='flex-start' bg='#FFF1EB'>
                            <Simulateur.Text>{credentials.codepartenaire ==='PAMFCI'?"Prime couverture de prêt :":"Prime décès emprunteur :"} </Simulateur.Text>
                            <Simulateur.Text variant='h5'>{(data && data.primeht && data.primeht !== '') && formatNumber(data.primeht) + " XOF"}</Simulateur.Text>
                        </Simulateur.Card>}
                        {(data && data.montantobseques!==0)&&<>
                            <Simulateur.Card direction='column' bg='#FFF1EB' style={{padding:20}}>
                            <Simulateur.TextSmall>Prime YAKO emprunteur : </Simulateur.TextSmall>
                            {(data && data.hasOwnProperty('typeadherent') && data.typeadherent ==='Groupe') ?(
                            <div className='flex flex-col'>
                                <p className='font-bold'>{data.montantobseques} x {data.nbadherent}</p>
                                <Simulateur.Text variant='h5'>{(data && data.montantobseques && data.montantobseques !== '' && data.nbadherent) && formatNumber(parseInt(data.montantobseques)*parseInt(data.nbadherent)) + " XOF"}</Simulateur.Text>
                            </div>
                            )
                            :<Simulateur.Text variant='h5'>{(data && data.montantobseques && data.montantobseques !== '') && formatNumber(data.montantobseques) + " XOF"}</Simulateur.Text>}
                        </Simulateur.Card>
                        </>}
                        

                        {credentials.codepartenaire ==='PAMFCI' && data.hasOwnProperty('primerisque')&&<Simulateur.Card direction='column' bg='#FFF1EB' style={{padding:20}}>
                            <Simulateur.TextSmall>Prime de risque emprunteur PAMF CI : </Simulateur.TextSmall>
                            <Simulateur.Text variant='h5'>{(data && data.primerisque && data.primerisque !== '') && formatNumber(data.primerisque) + " XOF"}</Simulateur.Text>
                        </Simulateur.Card>}
                        
                        {(data.fraisaccessoire && data.fraisaccessoire!==0)?<Simulateur.Card direction='column' bg='#FFF1EB' style={{padding:20}}>
                            <Simulateur.TextSmall>Frais acessoires : </Simulateur.TextSmall>
                            <Simulateur.Text variant='h5'>
                                {(data.fraisaccessoire && data.fraisaccessoire !== '') && formatNumber(data.fraisaccessoire) + " XOF"}
                            </Simulateur.Text>
                        </Simulateur.Card>:""}
                        {(!data.isnotconv)? <Simulateur.Card direction='column' bg={green[100]} style={{padding:20}}>
                            <Simulateur.TextSmall>{(credentials.codepartenaire==='092') ? "Prime BNI emprunteur" :"Prime totale" } : </Simulateur.TextSmall>
                            {(data && data.hasOwnProperty('typeadherent') && data.typeadherent ==='Groupe') ?(
                                <Simulateur.Text variant='h4'>{(data.montantobseques && data.nbadherent && data.primeht) && formatNumber(data.primeht + data.fraisaccessoire + (parseInt(data.montantobseques)*parseInt(data.nbadherent))+data.primerisque) + " XOF"}</Simulateur.Text>
                           )
                            :<Simulateur.Text variant='h4'>{(data.montantobseques!==undefined && data.primeht) && formatNumber(data.primeht + data.fraisaccessoire + data.montantobseques+data.primerisque) + " XOF"}</Simulateur.Text>}
                            
                        </Simulateur.Card>:null}
                        {(data && data.motifcotation && data.motifcotation.length!==0)&&(
                        <div className='bg-red-100 p-1 flex flex-row items-center'>   
                           <ReportIcon style={{color:red[700],fontSize:40}} />
                            <div>
                                {data.motifcotation.map((el, i) => <Typography variant="body1" style={{color:red[700],marginLeft:25}} component="h6">{`${i + 1} : ${el}`}</Typography>)}
                            </div>
                   </div>)}
                        
                        <div style={{display:'flex',flexDirection:'flex-end',padding:20}}>
                            <Button className={classes.goBouton} desabled={!data.isCotable} onClick={demarrerSouscription} variant='outlined' color='secondary'>{(errors && errors.length!==0) ? `Continuer la cotation ...`:`Demarrer une souscription`}</Button>
                        </div>
                    </Simulateur.Group>
                </Simulateur>)}
            </div>
        </form>
        </div>
    )
}



const updateField = (field, data) => (dispatch) => dispatch(change('addPret', field, data))
const mapPropsActions = {
    updateField,
}

const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    ui: state.UI,
    data: getFormValues('addPret')(state),
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({form:'addPret', destroyOnUnmount:false})(PretSimulation))