import DashboardPage from "pages/Dashboard/Dashboard.js";

//Production component
import { AccountCircle, Assignment, Add, } from "@material-ui/icons/";

//Material ui components
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import HealingIcon from '@material-ui/icons/Healing';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SchoolIcon from '@material-ui/icons/School';
import BusinessIcon from '@material-ui/icons/Business';
import FunctionsIcon from '@material-ui/icons/Functions';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ViewModuleSharpIcon from '@material-ui/icons/ViewModuleSharp';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Dashboard from "@material-ui/icons/Dashboard";
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
//Settings components
import ReseauList from 'pages/Settings/Reseau/ReseauList'
import ModuleList from 'pages/Settings/Module/ModuleList'
import ZoneList from 'pages/Settings/Zone/ZoneList'
import EquipeList from 'pages/Settings/Equipe/EquipeList'
import RoleList from 'pages/Settings/Role/RoleList'
import UtilisateurList from 'pages/Settings/Utilisateur/UtilisateurList'
import CommissionList from 'pages/Commons/Commissions/CommissionList'
//Banking components
import PretHome from "pages/Banking/Prets/PretHome"

import Simulateur from 'pages/Banking/Propositions/Simulateur'
import AdminHome from 'pages/Banking/Administration/AdminHome'
import ProductionHome from 'pages/Banking/ProdHome'
import ProductionCom from 'pages/Commercial/Propositions/ProductionHome'
import EtatHomeBank from 'pages/Banking/Etats/EtatHome'
import FormationHome from 'pages/Banking/Formation/FormationHome'

//producteur components
import ProducteurHome from 'pages/Producteur/ProducteurHome'
import ProducteurPropoHome from 'pages/Producteur/Propositions/PropositionHome'
import ProducteurOpeHome from 'pages/Producteur/Operations/OperationHome'
import ProducteurPretHome from 'pages/Producteur/Prets/PretsHome'
import OperationsHome from 'pages/Operations/OperationsHome'
import AcUnitIcon from '@material-ui/icons/AcUnit';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import MoneyIcon from '@material-ui/icons/Money';
import PartenaireHome from "pages/Settings/Partenaires/PartenaireHome";
import ProspectHome from 'pages/Commercial/Prospects/ProspectHome'
import BrancheList from "pages/Settings/Branche/BrancheList";
import SimulateurCom from 'pages/Commercial/Propositions/Simulateur'
//courtage
import AccueilCourtage from "pages/Courtier/AccueilCourtage";
import ProductionContainer from "pages/Courtier/Production/ProductionContainer"
import OperationContainer from "pages/Courtier/Operation/OperationContainer"
import FacturationHome from "pages/Facturation/FacturationHome"
//settings routes
import Epret from "Layouts/Epret";
const settings = [
  {
    path: "/reseau",
    name: "Reseau",
    icon: BusinessIcon,
    component: ReseauList,
    layout: "/settings"
  },
  {
    path: "/zone",
    name: "Zone",
    icon: BusinessCenterIcon,
    component: ZoneList,
    layout: "/settings"
  },
  {
    path: "/equipe",
    name: "Equipe",
    icon: GroupWorkIcon,
    component: EquipeList,
    layout: "/settings"
  },
  {
    path: "/modules",
    name: "Modules",
    icon: ViewModuleSharpIcon,
    component: ModuleList,
    layout: "/settings",
    partenaire: []
  },
  {
    path: "/profil",
    name: "Profil",
    icon: SupervisedUserCircleIcon,
    component: RoleList,
    layout: "/settings"
  },
  {
    path: "/utilisateur",
    name: "Utilisateur",
    icon: AccountCircle,
    component: UtilisateurList,
    layout: "/settings"
  },
  {
    path: "/partenaire",
    name: "Partenaire",
    icon: ViewComfyIcon,
    component: PartenaireHome,
    layout: "/settings"
  },
  {
    path: "/branche",
    name: "Branche",
    icon: AcUnitIcon,
    component: BrancheList,
    layout: "/settings"
  },
]

//admin routes
const routes = [
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/propositions",
    name: "Production",
    icon: FolderOpenIcon,
    component: DashboardPage,
    layout: "/admin"
  },

  {
    path: "/nouvelle-souscription",
    name: "Souscription",
    icon: Add,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/contrats",
    name: "Contrats",
    icon: Assignment,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/prestations",
    name: "Prestations",
    icon: FolderIcon,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/Sinistres",
    name: "Sinistres",
    icon: HealingIcon,
    component: EquipeList,
    layout: "/admin"
  },

  {
    path: "/cotisation",
    name: "Etat d'encaissement",
    icon: AccountBalanceWalletIcon,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/formations",
    name: "Formation",
    icon: SchoolIcon,
    component: DashboardPage,
    layout: "/admin"
  },

]

//commercials
const com = [
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/com",
    partenaire: ['LLV', 'COFINA']
  },
  {
    path: "/propositions",
    name: "Production",
    icon: FolderOpenIcon,
    component: ProductionCom,
    layout: "/com",
    partenaire: ['LLV', 'COFINA']
  },
  {
    path: "/simulateurs",
    name: "Simulation",
    icon: FunctionsIcon,
    component: SimulateurCom,
    layout: "/com",
    partenaire: ['LLV']
  },
  {
    path: "/commissions",
    name: "Commissions",
    icon: MoneyIcon,
    component: CommissionList,
    layout: "/com",
    partenaire: ['LLV']
  },
  {
    path: "/prospects",
    name: "Prospects",
    icon: FolderIcon,
    component: ProspectHome,
    layout: "/com",
    partenaire: ['LLV']
  },
  {
    path: "/equipes",
    name: "Equipes",
    icon: GroupWorkIcon,
    component: EquipeList,
    layout: "/com",
    partenaire: ['LLV']
  },
]

//banque assurance routes
const bankass = [
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/bankass",
    partenaire: ['WF', '092', 'AFC','PAMFCI']
  },
  {
    path: "/production",
    name: "Production",
    icon: FolderOpenIcon,
    component: ProductionHome,
    layout: "/bankass",
    partenaire: ['092', 'AFC']
  },

  {
    path: "/suivi-operations",
    name: "Prestations",
    icon: FolderIcon,
    component: OperationsHome,
    layout: "/bankass",
    partenaire: ['092']
  },
  {
    path: "/etats",
    name: "Etats",
    icon: AccountBalanceWalletIcon,
    component: EtatHomeBank,
    layout: "/bankass",
    partenaire: ['092', 'AFC']
  },
  {
    path: "/epret",
    name: "Gestion des prêts",
    icon: CreditCardIcon,
    component: Epret,
    partenaire: ['WF', '092','PAMFCI'],
    layout: "/bankass"
  },
  {
    path: "/formations",
    name: "Formation",
    icon: SchoolIcon,
    component: FormationHome,
    layout: "/bankass",
    partenaire: ['WF', '092', 'AFC']
  },
  
  {
    path: "/simulateurs",
    name: "Souscription",
    icon: Add,
    component: Simulateur,
    layout: "/bankass",
    partenaire: ['092', 'AFC']
  },

  {
    path: "/administration",
    name: "Administration",
    icon: AccountCircle,
    component: AdminHome,
    layout: "/bankass",
    partenaire: ['WF', '092','PAMFCI']
  },
]

//producteur routes
const producteur = [
  {
    path: "/home",
    name: "Accueil",
    icon: HomeIcon,
    component: ProducteurHome,
    layout: "/producteur"
  },
  {
    path: "/suivi-proposition",
    name: "Proposition",
    icon: LibraryBooksIcon,
    component: ProducteurPropoHome,
    layout: "/producteur"
  },

  {
    path: "/facturation",
    name: "Facturations",
    icon: AccountBalanceWalletIcon,
    component: FacturationHome,
    layout: "/producteur"
  },
  {
    path: "/suivi-operations",
    name: "Opérations",
    icon: FolderIcon,
    component: ProducteurOpeHome,
    layout: "/producteur"
  },
  {
    path: "/suivi-prets",
    name: "Gestion des prêts",
    icon: CreditCardIcon,
    component: ProducteurPretHome,
    layout: "/producteur"
  },
]
const technique =[
  {
    path: "/home",
    name: "Accueil",
    icon: HomeIcon,
    component: ProducteurHome,
    layout: "/technique"
  },
  {
    path: "/suivi-operations",
    name: "Opérations",
    icon: FolderIcon,
    component: ProducteurOpeHome,
    layout: "/technique"
  },
]
const courtage = [
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: AccueilCourtage,
    layout: "/courtage",
    partenaire: ['ASC']
  },
  {
    path: "/productions",
    name: "Souscriptions",
    icon: FolderOpenIcon,
    component: ProductionContainer,
    layout: "/courtage",
    partenaire: ['ASC']
  },
  {
    path: "/operations",
    name: "Prestations",
    icon: FolderIcon,
    component: OperationContainer,
    layout: "/courtage",
    partenaire: ['ASC']
  },
  {
    path: "/facturation",
    name: "Factures",
    icon: AccountBalanceWalletIcon,
    component: FacturationHome,
    layout: "/courtage",
    partenaire: ['ASC']
  },
  {
    path: "/conventions",
    name: "Conventions / produits",
    icon: ShoppingBasketOutlinedIcon,
    component: FacturationHome,
    layout: "/courtage",
    partenaire: ['ASC']
  },
  {
    path: "/reseau",
    name: "Reseau",
    icon: GroupWorkIcon,
    component: FacturationHome,
    layout: "/courtage",
    partenaire: ['ASC']
  },
  
]

const branche=[
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: AccueilCourtage,
    layout: "/branche"
  },
  {
    path: "/productions",
    name: "Productions",
    icon: FolderOpenIcon,
    component: ProductionContainer,
    layout: "/branche"
  },
  {
    path: "/conventions",
    name: "Produit / Conventions",
    icon: ShoppingBasketOutlinedIcon,
    component: FacturationHome,
    layout: "/branche"
  },
  {
    path: "/partenaire",
    name: "Partenaires",
    icon: ViewComfyIcon,
    component: PartenaireHome,
    layout: "/branche"
  },
]
export { routes as aroutes, settings, bankass, producteur, com,courtage,branche,technique };

/*
  {
    path: "/pret-liste",
    name: "Gestion des prêts",
    icon: CreditCardIcon,
    component: PretList,
    layout: "/bankass"
  },*/