import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { reduxForm, formValueSelector, change, getFormValues, registerField } from 'redux-form'
import { connect } from 'react-redux';
import { Grid, Box } from '@material-ui/core'
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import 'jspdf-autotable'
import dayjs from 'dayjs'
import MuiAlert from '@material-ui/lab/Alert';
import {
  savegardePret,
  updatePret
} from 'redux/actions/apiActions'
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import numeral from 'numeral'
import 'numeral/locales/fr'
import CircularProgress from '@material-ui/core/CircularProgress';
import { ageAssure } from 'redux/actions/userActions';
import { Container } from '../addprets/components/style'
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import AlertSucces from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import { generateBulletin, genFicheDemandeExamen } from 'application'
import TableContainer from '@material-ui/core/TableContainer';
import Chip from '@material-ui/core/Chip';
import FormLabel from '@material-ui/core/FormLabel';
import InfoMedecin from '../addprets/InfoMedecin';
const styles = (theme) => ({
  root: {
    marginBottom: 10,
    padding: 10
  },
  btnNext: {
    backgroundColor: theme.palette.primary.main,
    height: 40,
    width: 150,
    borderRadius: 20,
    color: theme.palette.primary.contrastText
  },
  assureInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-between'
  }
  ,
  pwarning: {
    backgroundColor: red[100],
    padding: 20,
    width: '%100',
    color:red[500]
  },
  chipRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1),
    marginBottom: 10,
  },
  chip: {
    margin: theme.spacing(1),
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:green[400],
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
})


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
//button succès
const SuccesButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

//bouton error
const ErrorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);
//composant PretFicheresume
function PretFicheresume(props) {
  const {
    classes, handleSubmit,
    data: { numerocompte, civiliteSous, nomSous, prenomSous, datenaissance, lieuNaissanceSous, pieceIdentiteSous, naturePiece,
      professionSous, employeur, emailSous, adressePostaleSous, telephoneSous, mobileSous, mobile2Sous, typepret, montantpret, effetGarantie, dateEcheanceDebut,
      dateEcheanceFin, remboursement, periodicite, txPrimeUnique, txSurprime, txPrimeDef, prime, primeht, duree, taille, poids,
      fumezVous, nbCigaretteJour, buvezVous, distraction, estInfirme, natureInfirmite, estEnArretTravail, fraisaccessoires,typeadherent,nbadherent,adherentconex,
      dateArretTravail, beneficiaireaudeces, beneficiaires,medecin, referencepret, datemiseenplace, motifArret, dateRepriseTravail, causeArretTravail, dateCauseTravail, tension, motifcotation, montantsurprime, examens, examensup, agence,
    },
    questionaires,
    handleBack,
    handleNext,
    handleAttente,
    activeStep,
    steps,
    user: { credentials },
    setSvData
  } = props
 
  const [loading, setloading] = useState(false)
  const [succes, setSucces] = useState(false)
  const [newContrat, setNewContrat] = useState(null)
  const _format = () => {
    numeral.locale('fr');
  }
  {/*Alerte modal status enregistrement*/ }

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (succes) {
      const propData = Array.isArray(newContrat) ? newContrat.length !== 0 ? newContrat[0] : {} : newContrat
      if (!props.hasOwnProperty('id') && propData) {
        props.dispatch(registerField("addPret", 'id', 'Field'));
        props.updateField('id', propData.id)
      }
      setSvData(propData)
      if (examensup === true){
        genFicheDemandeExamen(propData)
        handleNext()
      } else {
        generateBulletin(propData)
        handleNext()
      }
    }
  };

  const [resumeStep,setResumeStep] =useState(1)
  //props.updateField('examensup', false)
  const AlerteForSave = () => {
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{
              succes ? (<AlertSucces icon={<CheckIcon fontSize="inherit" />} severity="success">
                <Typography variant='h4' color={green[400]}>Enregistrement effectué avec succès.</Typography> </AlertSucces>) : (
                <AlertSucces severity="error"><Typography variant='h4' color={red[400]}>Le système a rencontré une ereur lors de l'enrégistrement.</Typography></AlertSucces>
              )
            }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {succes ? (
              <SuccesButton onClick={handleClose} variant="contained" color="primary" className={classes.margin}>
                OK
              </SuccesButton>
            ) : (
              <ErrorButton onClick={handleClose} variant="contained" color="primary" className={classes.margin}>
                OK
              </ErrorButton>
            )}

          </DialogActions>
        </Dialog>
      </div>
    )
  }
  //savegarde des données
  const submit = (values) => {
 
    let mesDonnees
    let qtBody = []
    let maperiodeDeb
    let periodeFin
    mesDonnees = JSON.stringify(values, null, 2)
    mesDonnees = JSON.parse(mesDonnees)
    mesDonnees.questionnaires = questionaires
    mesDonnees.motifcotation = mesDonnees.hasOwnProperty('motifcotation') ? mesDonnees.motifcotation?.join(";") : ""
    mesDonnees.beneficiaireaudeces = mesDonnees.hasOwnProperty('beneficiaireaudeces') ? mesDonnees.beneficiaireaudeces?.join(";") : ''
    mesDonnees.encotation = (examensup !== true) ? false : true
    setloading(true)
    let idx = 0
    if (mesDonnees.hasOwnProperty('mode') && mesDonnees.mode === 'UPDATE') {
      mesDonnees.typeop = 'UP'
      mesDonnees.etat = (mesDonnees.hasOwnProperty('eta') && mesDonnees.etat === 4) ? 1 : (examensup !== true) ? 2 : 1
      props.updatePret(mesDonnees).then(response => {
        idx = 0
        questionaires.map((q, index) => {
          maperiodeDeb = props.data[`periode-d-${q.id}`]
          periodeFin = props.data[`periode-f-${q.id}`]
          qtBody.push({
            id: (index !== 6) ? idx : "",
            nature: q.nature,
            reponse: props.data[`nature-${q.id}`],
            valeur: props.data[`valeurNature-${q.id}`],
            periode: (maperiodeDeb && periodeFin) && (dayjs(maperiodeDeb).format('DD/MM/YYYY') + "-" + dayjs(periodeFin).format('DD/MM/YYYY')),
            lieu: props.data[`lieu-${q.id}`]
          })
        })
        if (response) {
          response.questionnaires = qtBody
          response.user = credentials
          setNewContrat(response)
          setSucces(true)
          setloading(false)
          handleClickOpen()
        } else {
          setloading(false)
          setSucces(false)
          handleClickOpen()
          return
        }
      })
    } else {
      mesDonnees.etat = (examensup !== true) ? 2 : 1
      mesDonnees.encotation = (examensup !== true) ? false : true
      mesDonnees.montantpret= parseInt(mesDonnees.montantpret.replace(/\s+/g, ''))
      mesDonnees.typeop = 'AD'
      props.savegardePret(mesDonnees).then(response => {
        idx = 0
        questionaires.map((q, index) => {
          maperiodeDeb = props.data[`periode-d-${q.id}`]
          periodeFin = props.data[`periode-f-${q.id}`]
          qtBody.push({
            id: (index !== 6) ? idx : "",
            nature: q.nature,
            reponse: props.data[`nature-${q.id}`],
            valeur: props.data[`valeurNature-${q.id}`],
            periode: (maperiodeDeb && periodeFin) && (dayjs(maperiodeDeb).format('DD/MM/YYYY') + "-" + dayjs(periodeFin).format('DD/MM/YYYY')),
            lieu: props.data[`lieu-${q.id}`]
          })
        })

        if (response) {
          response.questionnaires = qtBody
          response.user = credentials
          setNewContrat(response)
          setSucces(true)
          setloading(false)
          handleClickOpen()
        } else {
          setloading(false)
          setSucces(false)
          handleClickOpen()
          return
        }
      })
    }
  }

  const backresueme = ()=>{
    setResumeStep(resumeStep - 1)
  }
  const nextresueme = (e)=>{
    setResumeStep(resumeStep + 1)
  }



  return (
    <div>
        {(examensup && activeStep===4 && resumeStep===1) ? (<InfoMedecin handleSubmit={handleSubmit} handleBack={handleBack} handleNext={nextresueme} handleAttente={handleAttente} steps={steps} activeStep={activeStep}  />) : (
            <Container>
            {_format()}
            {AlerteForSave()}
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Card className={classes.root} variant="outlined">
                <GridContainer>
                  <GridItem xs={12} sm={8} md={8} lg={8}><Typography variant='h4'> FICHE DEMANDE DE COTATION</Typography></GridItem>
                  <GridItem xs={12} sm={4} md={4} ld={4}>{dayjs().format('DD/MM/YYYY')}</GridItem>
                </GridContainer>
              </Card>
              {(examensup === true) && (
                <Paper variant='outline' style={{padding:20,margin:20,backgroundColor:red[100],color:red[500],fontSize:16,fontWeight:'bold'}}>
                    ** Cette demande de cotation necessite des examens supplementaires **
                </Paper>
               
              )}
      
      
              <Card className={classes.root} variant="outlined">
                  <Typography variant='h5'>ADHERENT</Typography>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Titre :
                          </Typography>
                          <Typography variant="subtitle1">
                            {`\xa0 ${civiliteSous ? (civiliteSous === 'M' ? "Monsieur" : civiliteSous === 'Mme' ? "Madame" : "Mademoiselle") : ""}`}
                          </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Nom :
                          </Typography>
                          <Typography variant="subtitle1">
                            {`\xa0 ${nomSous ? nomSous : ""}`}
                          </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Prénoms :
                          </Typography>
                          <Typography variant="subtitle1">{`\xa0 ${prenomSous && prenomSous}`} </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Date de naissance :
                          </Typography>
                          <Typography variant="subtitle1">{`\xa0 ${dayjs(datenaissance).format('DD/MM/YYYY')}`} </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Age :
                          </Typography>
                          <Typography variant="subtitle1">{`\xa0 ${ageAssure(datenaissance)} ans`} </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Lieu de naissance:
                          </Typography>
                          <Typography variant="subtitle1">{`\xa0 ${lieuNaissanceSous && lieuNaissanceSous}`} </Typography>
                        </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          N° {naturePiece && naturePiece} :
                        </Typography>
                        <Typography variant="subtitle1">{`\xa0 ${pieceIdentiteSous && pieceIdentiteSous}`} </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Profession :
                        </Typography>
                        <Typography variant="subtitle1">
                          {`\xa0 ${professionSous ? professionSous : ""}`}
                        </Typography>
                      </Box>
                      {employeur && (
                        <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Employeur :
                          </Typography>
                          <Typography variant="subtitle1">
                            {`\xa0 ${employeur && employeur}`}
                          </Typography>
                        </Box>
                      )}
      
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Email :
                        </Typography>
                        <Typography variant="subtitle1">
                          {`\xa0 ${emailSous ? emailSous : ""}`}
                        </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Téléphone:
                        </Typography>
                        <Typography variant="subtitle1">{`\xa0 ${telephoneSous ? telephoneSous : ""}`} </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Mobile :
                        </Typography>
                        <Typography variant="subtitle1">{`\xa0 ${mobileSous ? mobileSous : ""}`} </Typography>
                      </Box>
                      {mobile2Sous && (
                        <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Mobile 2 :
                          </Typography>
                          <Typography variant="subtitle1">{`\xa0 ${mobile2Sous ? mobile2Sous : ""}`} </Typography>
                        </Box>
                      )}
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Adresse postale :
                        </Typography>
                        <Typography variant="subtitle1">{`\xa0 ${adressePostaleSous ? adressePostaleSous : ""}`}  </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {(typeadherent==='Groupe' && adherentconex && adherentconex.length!==0)&&<Card className={classes.root} variant="outlined">
                  <Typography variant='h5'>AUTRE(S) ADHERENT(S)</Typography>
                  <CardContent>
                    <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nom</TableCell>
                          <TableCell align="center">Né(e) le</TableCell>
                          <TableCell align="center">Lieu de naissance</TableCell>
                          <TableCell align="center">Lieu de residence</TableCell>
                          <TableCell align="center">Profession</TableCell>
                          <TableCell align="center">Téléphone</TableCell>
                          <TableCell align="center">Email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {adherentconex.map(adconnexe=>(<TableRow key={adconnexe.nomBeneficiaire}>
                            <TableCell component="th" scope="row">
                              {`${adconnexe.civiliteSous} ${adconnexe.nomSous} ${adconnexe.prenomSous}`}
                            </TableCell>
                            <TableCell align="center">{dayjs(adconnexe.datenaissance).format('DD/MM/YYYY')}</TableCell>
                            <TableCell align="center">{adconnexe.lieuNaissanceSous}</TableCell>
                            <TableCell align="center">{adconnexe.lieuresidence}</TableCell>
                            <TableCell align="center">{adconnexe['professionSous'] || ""}</TableCell>
                            <TableCell align="center">{adconnexe['mobileSous'] || ""}</TableCell>
                            <TableCell align="center">{adconnexe['emailSous'] || ""}</TableCell>      
                          </TableRow>
                      ))}
                     </TableBody>
                    </Table>
                    </TableContainer>
                  </CardContent>
              </Card>}

              <Card className={classes.root} variant="outlined">
                
                  <Typography variant='h5'>ETAT DE SANTE</Typography>
               
                <CardContent>
                  <GridContainer>
                    <GridItem sm={4} xs={4}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Taille (cm) :
                        </Typography>
                        <Typography variant="subtitle1">
                          {`\xa0 ${taille && taille}`}
                        </Typography>
                      </Box>
                    </GridItem>
                    <GridItem sm={4} xs={4}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Poids(Kg) :
                        </Typography>
                        <Typography variant="subtitle1">
                          {`\xa0 ${poids && poids}`}
                        </Typography>
                      </Box>
                    </GridItem>
      
                  </GridContainer>
                  <GridContainer>
                    <GridItem sm={4} xs={4}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Fumeur ?
                        </Typography>
                        <Typography variant="subtitle1">
                          {`\xa0 ${fumezVous && fumezVous}`}
                        </Typography>
                      </Box>
                    </GridItem>
                    {(fumezVous === 'Oui') && (
                      <GridItem sm={4} xs={4}>
                        <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Nombre cirgarette/jour ?
                          </Typography>
                          <Typography variant="subtitle1">
                            {`\xa0 ${nbCigaretteJour ? nbCigaretteJour : ""}`}
      
                          </Typography>
                        </Box>
                      </GridItem>
                    )}
                  </GridContainer>
                  <GridContainer>
                    <GridItem sm={6} xs={6}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Buvez vous l'alcool ?
                        </Typography>
                        <Typography variant="subtitle1">
                          {buvezVous && (`\xa0 ${buvezVous && (buvezVous==="Non"?"Pas du tout":(buvezVous==="Partiel"?"A l'ocassion":"Régulièrement (au moins une fois par semaine)"))}`)}
      
                        </Typography>
                      </Box>
                    </GridItem>
                    <GridItem sm={6} xs={6}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Vos distractions :
                        </Typography>
                        <Typography variant="subtitle1">
                          {distraction && (`\xa0 ${distraction}`)}
      
                        </Typography>
                      </Box>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem sm={6} xs={6}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Etes-vous atteint d'une infirmité ?
                        </Typography>
                        <Typography variant="subtitle1">
                          {estInfirme && (`\xa0 ${estInfirme}`)}
      
                        </Typography>
                      </Box>
                    </GridItem>
      
                    {estInfirme === 'Oui' && (
                      <GridItem sm={6} xs={6}>
                        <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Nature infirmité
                          </Typography>
                          <Typography variant="subtitle1">
                            {natureInfirmite && (`\xa0 ${natureInfirmite}`)}
      
                          </Typography>
                        </Box>
                      </GridItem>
                    )}
                  </GridContainer>
                  <GridContainer>
                    <GridItem sm={6} xs={6}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Etes-vous en arrêt de travail?
                        </Typography>
                        <Typography variant="subtitle1">
                          {estEnArretTravail && (`\xa0 ${estEnArretTravail}`)}
      
                        </Typography>
                      </Box>
                    </GridItem>
                    {estEnArretTravail === 'Oui' && (
                      <>
                        <GridItem sm={3} xs={3}>
                          <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Date d'arrêt
                            </Typography>
                            <Typography variant="subtitle1">
                              {dateArretTravail && (`\xa0 ${dayjs(dateArretTravail).add(duree, 'year').format('DD/MM/YYYY')}`)}
      
                            </Typography>
                          </Box>
                        </GridItem>
                        <GridItem sm={3} xs={3}>
                          <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Motif de l'arrêt
                            </Typography>
                            <Typography variant="subtitle1">
                              {motifArret && (`\xa0 ${motifArret}`)}
                            </Typography>
                          </Box>
                        </GridItem>
                        <GridItem sm={3} xs={3}>
                          <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Date réprise prévu
                            </Typography>
                            <Typography variant="subtitle1">
                              {dateRepriseTravail && (`\xa0 ${dayjs(dateRepriseTravail).add(duree, 'year').format('DD/MM/YYYY')}`)}
                            </Typography>
                          </Box>
                        </GridItem>
                        <GridItem sm={4} xs={4}>
                          <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Cause de l'arrêt
                            </Typography>
                            <Typography variant="subtitle1">
                              {causeArretTravail && (`\xa0 ${causeArretTravail}`)}
                            </Typography>
                          </Box>
                        </GridItem>
                        <GridItem sm={3} xs={3}>
                          <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Date de la cause
                            </Typography>
                            <Typography variant="subtitle1">
                              {dateCauseTravail && (`\xa0 ${dayjs(dateCauseTravail).add(duree, 'year').format('DD/MM/YYYY')}`)}
                            </Typography>
                          </Box>
                        </GridItem>
                      </>
                    )}
                  </GridContainer>
                  <Divider />
                  {questionaires && (
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">#</TableCell>
                          <TableCell align="center">Questions</TableCell>
                          <TableCell align="center">Reponse</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {questionaires.map((question) => (
                          <TableRow key={question.id}>
                            <TableCell align="center" component="td" scope="row">{question.id}</TableCell>
                            <TableCell align="left" component="td" scope="row">{question.nature}</TableCell>
                            <TableCell align="left" component="td" scope="row">{props.data[`nature-${question.id}`]}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </CardContent>
      
              </Card>
              <Card className={classes.root} variant="outlined">
                
                  <Typography variant='h5'>DETAILS DU PRET</Typography>
                
                <CardContent>
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          N° Compte:
                        </Typography>
                        <Typography variant="subtitle1"> {`\xa0 ${numerocompte}`} </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Type :
                        </Typography>
                        <Typography variant="subtitle1">
                          {`\xa0 ${typepret && (typepret==='consommation'?"Prêt à la consommation":"Avance de trésorerie")}`}
      
                        </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Date de mise en place prévue:
                        </Typography>
                        <Typography variant="subtitle1"> {`\xa0 ${dayjs(effetGarantie).format('DD/MM/YYYY')}`} </Typography>
                      </Box>
      
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Début remboursement :
                        </Typography>
                        <Typography variant="subtitle1">{`\xa0 ${dayjs(dateEcheanceDebut).format('DD/MM/YYYY')}`} </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Fin remboursement :
                        </Typography>
                        <Typography variant="subtitle1"> {dayjs(dateEcheanceFin).format('DD/MM/YYYY')}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Périodicité de remboursement :
                        </Typography>
                        <Typography variant="subtitle1">
                          {`\xa0 ${remboursement}`}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Option:
                        </Typography>
                        <Typography variant="subtitle1">{`\xa0 ${typeadherent}`} </Typography>
                      </Box>
                      {(nbadherent && nbadherent!==0)&&<Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Nb. Adhérent :
                        </Typography>
                        <Typography variant="subtitle1">{`\xa0 ${nbadherent}`} </Typography>
                      </Box>}
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Prériodicité de paiement de la prime:
                        </Typography>
                        <Typography variant="subtitle1">{`\xa0 ${periodicite}`} </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Durée (Mois) :
                        </Typography>
                        <Typography variant="subtitle1">{`\xa0 ${duree}`} </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Montant du prêt (F CFA) :
                        </Typography>
                        <Typography variant="subtitle1">{`\xa0 ${montantpret}`} </Typography>
                      </Box>
                      {(examensup !== true) && (<Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} style={{ fontSize: 26, color: 'red' }}>
                          Prime (XOF) :
                        </Typography>
                        <Typography style={{ fontSize: 26, color: 'red' }} variant="subtitle1">{`\xa0 ${ (isNaN(prime) || prime==="")?"Non déterminée" :parseInt(prime).toLocaleString()}`} </Typography>
                      </Box>)}
                    </Grid>
                  </Grid>
      
                </CardContent>
      
              </Card>
              <Card className={classes.root} variant="outlined">
               
                  <Typography variant='h5'>BENEFICIAIRE(S)</Typography>
               
                <CardContent>
      
                  <Grid container>
                    <Grid container>
                      {(beneficiaireaudeces && beneficiaireaudeces.length !== 0) && <Grid item sm={12} xs={12} md={12} lg={12}>
                        <Grid item xs={12} sm={12} md={6} lg={16}>
                          <FormLabel component="h6">Bénéficaire en cas de décès avant le terme</FormLabel>
                          <Paper component="ul" className={classes.chipRoot}>
                            {beneficiaireaudeces.map((option) => {
                              let libelle = ""
                              switch (option) {
                                case 'conjoint':
                                  libelle = "Le conjoint non divorcé, ni séparé de corps"
                                  break;
                                case 'enfants':
                                  libelle = "Les enfants nés et à naître"
                                  break;
                                default:
                                  libelle = "Autres"
                                  break;
                              }
                              return <li key={option}>
                                <Chip
                                  icon={null}
                                  label={libelle}
                                  className={classes.chip}
                                />
                              </li>
                            })}
                          </Paper>
                        </Grid>
                      </Grid>
                      }
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={12}>
                      {(beneficiaires && beneficiaires.length !== 0) ? (<TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Nom</TableCell>
                              <TableCell>Prénoms</TableCell>
                              <TableCell align="center">Né(e) le</TableCell>
                              <TableCell align="center">Lieu de naissance</TableCell>
                              <TableCell align="center">Lieu de residence</TableCell>
                              <TableCell align="center">Filiation</TableCell>
                              <TableCell align="center">Téléphone</TableCell>
                              <TableCell align="center">Email</TableCell>
                              <TableCell align="center">Taux (%)</TableCell>
                              <TableCell align="center">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(beneficiaires && typeadherent!=='Groupe')&& beneficiaires.map(beneficiaire => (
                              <TableRow key={beneficiaire.nomBeneficiaire}>
                                <TableCell component="th" scope="row">
                                  {beneficiaire.nomBeneficiaire}
                                </TableCell>
                                <TableCell align="center">{beneficiaire.prenomBeneficiaire}</TableCell>
                                <TableCell align="center">{dayjs(beneficiaire.dateNaissanceBeneficiaire).format('DD/MM/YYYY')}</TableCell>
                                <TableCell align="center">{beneficiaire.lieuNaissanceBeneficiaire}</TableCell>
                                <TableCell align="center">{beneficiaire.lieuResidenceBeneficiaire}</TableCell>
                                <TableCell align="center">{beneficiaire.filiationBeneficiaire}</TableCell>
                                <TableCell align="center">{beneficiaire.telephoneBeneficiaire}</TableCell>
                                <TableCell align="center">{beneficiaire.emailBeneficiaire}</TableCell>
                                <TableCell align="center">{beneficiaire.taux}</TableCell>
                                <TableCell align="center"></TableCell>
                              </TableRow>
                            ))}

                            {(typeadherent==='Groupe' && adherentconex && adherentconex.length!==0)&&(
                              adherentconex.map(ad=>{
                                if(ad.hasOwnProperty('beneficiaires') && ad.beneficiaires.length!==0){
                                  return ad.beneficiaires.map(adbenef=>(
                                    <TableRow key={adbenef.nomBeneficiaire}>
                                <TableCell component="th" scope="row">
                                  {adbenef.nomBeneficiaire}
                                </TableCell>
                                <TableCell align="center">{adbenef.prenomBeneficiaire}</TableCell>
                                <TableCell align="center">{dayjs(adbenef.dateNaissanceBeneficiaire).format('DD/MM/YYYY')}</TableCell>
                                <TableCell align="center">{adbenef.lieuNaissanceBeneficiaire}</TableCell>
                                <TableCell align="center">{adbenef.lieuResidenceBeneficiaire}</TableCell>
                                <TableCell align="center">{adbenef.filiationBeneficiaire}</TableCell>
                                <TableCell align="center">{adbenef.telephoneBeneficiaire}</TableCell>
                                <TableCell align="center">{adbenef.emailBeneficiaire}</TableCell>
                                <TableCell align="center">{adbenef.taux}</TableCell>
                                <TableCell align="center"></TableCell>
                              </TableRow>
                                  ))
                                }else{
                                  return null
                                }
                              })
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>) : null}
      
                    </Grid>
                  </Grid>
      
                </CardContent>
      
              </Card>
              {medecin && medecin.length!==0&&<Card className={classes.root} variant="outlined">
              <Typography variant='h5'>MEDECIN TRAITANT</Typography>
                <CardContent>
                  {(medecin) ? (<TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>N° ONMCI</TableCell>
                              <TableCell>Nom</TableCell>
                              <TableCell align="center">Ville</TableCell>
                              <TableCell align="center">Téléphone</TableCell>
                              <TableCell align="center">Téléphone</TableCell>
                              <TableCell align="center">Email</TableCell>
                              
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {beneficiaires.map(beneficiaire => (
                              <TableRow key={beneficiaire.nomBeneficiaire}>
                                <TableCell component="th" scope="row">
                                  {beneficiaire.nomBeneficiaire}
                                </TableCell>
                                <TableCell align="center">{medecin.numonmci}</TableCell>
                                <TableCell align="center"> {`${medecin.titre} ${medecin.nom} ${medecin.prenom}`}</TableCell>
                                <TableCell align="center"> {`${medecin.ville}`}</TableCell>
                                <TableCell align="center">{`${medecin.mobile} /${medecin.mobile}`}</TableCell>
                                <TableCell align="center">{`${medecin.email}`}</TableCell>
                            
                                
                                <TableCell align="center"></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>) : null}
                </CardContent>
              </Card>}
              
              {(examensup === true) && (<Paper elevation={0} className={classes.pwarning}>
                <Typography variant="h6" style={{color:red[500]}} component="h6">Après validation votre demande sera transmise à YAKO AFRICA pour traitement</Typography>
                      <Typography style={{color:red[500]}} variant="h4" component="h4">Motifs : </Typography>
                      {(motifcotation && motifcotation.length !== 0) && (motifcotation.map((el, i) => <Typography variant="body1" style={{color:red[700],marginLeft:25}} component="h6">{`${i + 1} : ${el}`}</Typography>))}
              </Paper>)
      
              }
              <Divider component="hr" className={classes.hTrait} />
      
      
              <Grid container direction="row" justify="space-between" alignItems="center">
      
                <Button type="button" className={classes.btnback} onClick={(examensup && activeStep===4 && resumeStep===2)?backresueme:handleBack}>
                  Retour
                </Button>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleSubmit(handleAttente)}
      
                  variant="contained"
                  color="info"
                >
                  Mettre en attente
                </Button>
                {/* 
                    <Button type="submit" onClick={()=>generateBulletin('1010')}>
                          PDF
                    
                    </Button>*/}
                <Button className={classes.btnnext} disabled={loading} type="submit" onClick={handleSubmit(submit)}>
                  {!loading ? (
                    <>
                      Enregistrer
                      <NavigateNextOutlinedIcon />
                    </>
                  ) : (<CircularProgress size={30} />)}
                </Button>
              </Grid>
      
            </div>
          </Container>
        )}
    </div>

    

  )
}
const selector = formValueSelector('addPret')

const mapStateToProps = (state) => ({
  user: state.user,
  api: state.api,
  ui: state.UI,
  data: getFormValues('addPret')(state)
})



const updateField = (field, data) => (dispatch) => dispatch(change('addPret', field, data))
const mapActionsToProps = {
  updateField,
  savegardePret,
  updatePret
}
PretFicheresume = withStyles(styles)(PretFicheresume)


export default connect(mapStateToProps, mapActionsToProps)(reduxForm({form:'addPret', destroyOnUnmount:false, })(PretFicheresume))
