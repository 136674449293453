import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm, formValueSelector, change, registerField } from 'redux-form';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomSlider from './CustomSlider'
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import dayjs from 'dayjs'
import CustomDate from 'components/CustomDate/CustomDate'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getContratsConnexes } from 'redux/actions/apiActions'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { green } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import { compareStrings } from 'application';
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    rootExpan: {
        width: '100%',
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    paper: {
        padding: '20px',
    },
    list: {
        width: '100%',
        padding: 5
    },

    rootfab: {

    },
    extendedIcon: {

    },
    contain: {
        marginTop: "20px"
    },
    btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:green[400],
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
      btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },

};

const useStyles = makeStyles(styles);
function InfosPret(props) {
    const classes = useStyles();
    const { handleSubmit, updateField, duree, dateEcheanceDebut, user, handleAttente, dateEcheanceFin, numerocompte, handleBack, handleNext, steps, activeStep, dataForm } = props
    useEffect(() => {
        if (dateEcheanceDebut && duree) {
            const ecartMois = parseInt(duree) % 12
            const ecartAnnee = parseInt(parseInt(duree) / 12)
            const dateEcheance = dayjs(dateEcheanceDebut).add(ecartAnnee, 'year').add(ecartMois, 'month').format('YYYY-MM-DD')
            updateField('dateEcheanceFin', dateEcheance)
        }
    }, [dateEcheanceFin])

    useEffect(() => {
        if (dataForm && !dataForm.hasOwnProperty('effetGarantie')) {
            props.dispatch(registerField("addPret", 'effetGarantie', 'Field'));
        }
        props.updateField('effetGarantie', dayjs().date(1).add(1, 'month').format('YYYY-MM-DD'))

        if (dataForm && !dataForm.hasOwnProperty('dateEcheanceDebut')) {
            props.dispatch(registerField("addPret", 'dateEcheanceDebut', 'Field'));
        }
        props.updateField('dateEcheanceDebut', dayjs().date(1).add(1, 'month').format('YYYY-MM-DD'))

        if (dataForm && !dataForm.hasOwnProperty('typepret')) {
            props.dispatch(registerField("addPret", 'typepret', 'Field'));
            props.updateField('typepret', "consommation")
        }
        if (dataForm && !dataForm.hasOwnProperty('remboursement')) {
            props.dispatch(registerField("addPret", 'remboursement', 'Field'));
            props.updateField('rembourement', "Mensuelle")
        }
    }, [])

    //mise à jour de la date d'échéance
    useEffect(() => {
        if (dataForm && dataForm.hasOwnProperty('dateEcheanceDebut') && dataForm.hasOwnProperty('duree')) {
            let dateDebutCalcul = dataForm.hasOwnProperty('dateEcheanceDebut') ? dataForm.dateEcheanceDebut : new Date()
            if (dataForm && !dataForm.hasOwnProperty('dateEcheanceFin')) {
                props.dispatch(registerField("addPret", 'dateEcheanceFin', 'Field'));
            }
            if(dataForm  && dataForm.hasOwnProperty('typepret') && dataForm['typepret']==='decouvert'){
                props.dispatch(registerField("addPret", 'remboursement', 'Field'));
                props.updateField('remboursement', "Unique")
            }
            if (dataForm && dataForm.hasOwnProperty('remboursement')) {
                let ecart
                switch (dataForm.remboursement) {
                    case 'Trimestirelle':
                        ecart = duree ? (parseInt(duree / 3) + (duree % 3)) : 1
                        props.updateField('dateEcheanceFin', dayjs(dateDebutCalcul).date(1).add(ecart, 'month').format('YYYY-MM-DD'))
                        break;
                    case 'Semestrielle':
                        ecart = duree ? (parseInt(duree / 6) + (duree % 6)) : 1
                        props.updateField('dateEcheanceFin', dayjs(dateDebutCalcul).date(1).add(ecart, 'month').format('YYYY-MM-DD'))
                        break;
                    case 'Annuelle':
                        ecart = duree ? (parseInt(duree / 12) + (duree % 12)) : 1
                        props.updateField('dateEcheanceFin', dayjs(dateDebutCalcul).date(1).add(ecart, 'month').format('YYYY-MM-DD'))
                        break;
                    case 'Unique':
                        props.updateField('dateEcheanceFin', dayjs(dataForm.dateEcheanceDebut).format('YYYY-MM-DD'))
                        break;
                    default:
                        props.updateField('dateEcheanceFin', dayjs(dateDebutCalcul).date(1).add(parseInt(duree), 'month').format('YYYY-MM-DD'))
                        break;
                }
            } else {
                props.updateField('dateEcheanceFin', dayjs(dateDebutCalcul).date(1).add(duree ? duree : 1, 'month').format('YYYY-MM-DD'))
            }
        }

        if (user.credentials && user.credentials.codepartenaire === 'WF') {
            props.dispatch(registerField("addPret", 'periodicite', 'Field'));
            props.updateField('periodicite', 'Unique')
        }
        if (user.credentials && user.credentials.codepartenaire === '092' && (dataForm && dataForm.hasOwnProperty('remboursement'))) {
            props.dispatch(registerField("addPret", 'periodicite', 'Field'));
            props.updateField('periodicite', dataForm.remboursement)
        }

    })
    
    const [isValide,setIsValide] = useState(true)
    const handleIsValide = ()=>{
        if((user && user.hasOwnProperty('credentials') && user.credentials.codepartenaire === 'PAMFCI')){
            if(dataForm.hasOwnProperty('numerocompte') && dataForm.hasOwnProperty('numerocompteconfirm') && dataForm.hasOwnProperty('numeroclient')){
                if(dataForm.numerocompte !== dataForm.numerocompteconfirm){
                    setIsValide(false)
                }else{
                    setIsValide(true)
                }
            }else{
                setIsValide(false)
            }
        }
    }
    useEffect(()=>{
        handleIsValide()
    },[numerocompte,dataForm])
    const [contratsconnexe, setContratsConnexes] = useState(null)

    useEffect(() => {
        if (numerocompte) {
            getContratsConnexes({ numerocompte, codeguichet: dataForm.hasOwnProperty('agence') ? dataForm.agence : null }).then(dt => {
                if (dt && Array.isArray(dt)) {setContratsConnexes(dt)}
            })
        }
    }, [numerocompte])

    return (
        <form onSubmit={handleSubmit(handleNext)}>
            <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.heading}>Etape {activeStep ? (activeStep + 1) : 1} : {(steps && activeStep) ? steps[activeStep] : ""}</Typography>
                <Divider style={{ marginBottom: 20 }} />
                <GridContainer>
                    <GridItem  xs={12} sm={12} md={12} lg={12}>
                        <Field
                            required
                            component={CustomRadio}
                            label="Nature du prêt"
                            id="naturepret"
                            name='naturepret'
                            formControlProps={{fullWidth: true}}
                            options={[
                                { "label": "Nouveau prêt", "value": "Nouveau prêt" }, 
                                { "label": "Rachat interne", "value": "Ratchat interne"},
                                { "label": "Rachat externe", "value": "Rachat externe"}
                            ]}
                            row
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer className={classes.contain}>
                    <GridItem xs={12} sm={12} md={5} lg={5}>
                        <Field
                            component={CustomSlider}
                            id="montantpret"
                            name="montantpret"
                            formControlProps={{
                                fullWidth: true
                            }}
                            variant="outlined"
                            min={50000}
                            titre="Quel est le montant du prêt ? *"
                            max={1000000000}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2} />
                    <GridItem xs={12} sm={12} md={5} lg={5}>
                        <Field
                            component={CustomSlider}
                            id="duree"
                            name='duree'
                            formControlProps={{
                                fullWidth: true
                            }}
                            variant="outlined"
                            min={1}
                            titre="Quelle est la durée du prêt (Mois) ? *"
                            max={720}
                        />
                    </GridItem>
                </GridContainer>

                <Accordion expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        fullWidth
                    >
                        <Typography className={classes.heading}>Détails du prêt</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ display: "flex", width:'100%', flexDirection: "column", backgroundColor: "#f2f2f2" }}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <Field
                                        required
                                        component={CustomRadio}
                                        label="Type de prêt *"
                                        id="typepret"
                                        name='typepret'
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        options={[ 
                                            { "label": "Prêt à la consommation", "value": "consommation" }, 
                                            { "label": "Prêt immobilier", "value": "immobilier" }
                                        ]}
                                        row
                                    />
                                </GridItem>
                            </GridContainer>
                            {user.credentials && !['092'].includes(user.credentials.codepartenaire)&&<GridContainer>
                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <Field
                                        component={CustomDate}
                                        label="Date de mise en place prévue"
                                        id="effetGarantie"
                                        name="effetGarantie"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        variant="outlined"
                                        InputProps={{
                                            type: 'date',
                                            inputProps: { min: dayjs().date(1).add(1, 'month').format('YYYY-MM-DD'), max: dayjs().date(1).add(100, 'year').format('YYYY-MM-DD') },
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <Field
                                        required
                                        component={CustomDate}
                                        label="Prémière échéance de remboursement prévue"
                                        id="dateEcheanceDebut"
                                        name="dateEcheanceDebut"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        variant="outlined"
                                        InputProps={{
                                            type: 'date',
                                            inputProps: { min: dayjs().date(1).add(1, 'month').format('YYYY-MM-DD'), max: dayjs().date(1).add(100, 'year').format('YYYY-MM-DD') },
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <Field
                                        required
                                        component={CustomDate}
                                        label="Dernière échéance de remboursement prévue"
                                        id="dateEcheanceFin"
                                        name="dateEcheanceFin"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        variant="outlined"
                                        InputProps={{
                                            type: 'date',
                                            inputProps: { min: dayjs().format('YYYY-MM-DD'), max: dayjs().date(1).add(100, 'year').format('YYYY-MM-DD') },
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>}
                            
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <Field
                                        required
                                        component={CustomRadio}
                                        label="Périodicite de remboursement *"
                                        id="remboursement"
                                        name="remboursement"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        options={(user.credentials && ['PAMFCI'].includes(user.credentials.codepartenaire))?[{ "label": "Versement unique", "value": "Unique" }]:[{ "label": "Mensuelle", "value": "Mensuelle" }, { "label": "Trimestrielle", "value": "Trimestrielle" }, { "label": "Semestrielle", "value": "Semestrielle" }, { "label": "Annuelle", "value": "Annuelle" }, { "label": "Versement unique", "value": "Unique" }]}
                                        row
                                    />
                                </GridItem>
                                {(user.credentials && !['WF'].includes(user.credentials.codepartenaire)) && <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <Field
                                        required
                                        component={CustomRadio}
                                        label="Périodicité de paiement de la prime *"
                                        id="periodicite"
                                        name="periodicite"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        options={(user.credentials && ['PAMFCI'].includes(user.credentials.codepartenaire))?[{ "label": "Versement unique", "value": "Unique" }]:[{ "label": "Mensuelle", "value": "Mensuelle" }, { "label": "Trimestrielle", "value": "Trimestrielle" }, { "label": "Semestrielle", "value": "Semestrielle" }, { "label": "Annuelle", "value": "Annuelle" }, { "label": "Versement unique", "value": "Unique" }]}
                                        row
                                    />
                                </GridItem>}

                            </GridContainer>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>{(user && user.hasOwnProperty('credentials') && ['WF','PAMFCI'].includes(user.credentials.codepartenaire)) ? "N° Client/Compte" : "Compte bancaire"}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#f2f2f2", width: '100%' }}>
                            {(user && user.hasOwnProperty('credentials') && user.credentials.codepartenaire === 'WF') && (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Field
                                            component={CustomInput}
                                            label=""
                                            id="numerocompte"
                                            name="numerocompte"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: { max: 12 },
                                            }}
                                            variant="outlined"
                                            required
                                        />
                                    </GridItem>
                                </GridContainer>

                            )} 
                            
                            {(user && user.hasOwnProperty('credentials') && user.credentials.codepartenaire === '092') &&(<GridContainer>
                                    <GridItem xs={12} sm={3} md={3} lg={3}>
                                        <Field
                                            component={CustomInput}
                                            label="Code guichet"
                                            id="agence"
                                            name="agence"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            variant="outlined"
                                            InputProps={{
                                                inputProps: { max: 5 },
                                            }}

                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={7} md={7} lg={7}>
                                        <Field
                                            component={CustomInput}
                                            label="N° compte"
                                            id="numerocompte"
                                            name="numerocompte"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: { max: 12 },
                                            }}
                                            variant="outlined"
                                            required
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={2} md={2} lg={2}>
                                        <Field
                                            component={CustomInput}
                                            label="Clé rib"
                                            id="rib"
                                            name="rib"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: { max: 99 },
                                            }}
                                            type='number'
                                            variant="outlined"
                                        />
                                    </GridItem>
                                </GridContainer>)} 
                                {(user && user.hasOwnProperty('credentials') && user.credentials.codepartenaire === 'PAMFCI') &&(<GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Field
                                            component={CustomInput}
                                            label="N° Client"
                                            id="numeroclient"
                                            name="numeroclient"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: { max: 12 },
                                            }}
                                            variant="outlined"
                                            required
                                        />
                                    </GridItem>
                                   <div style={{display:'flex', flex:1, alignItems:'center', paddingRight:20}}>
                                          
                                                    <Field
                                                    component={CustomInput}
                                                    label="N° compte"
                                                    id="numerocompte"
                                                    name="numerocompte"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    InputProps={{
                                                        inputProps: { max: 12 },
                                                    }}
                                                    variant="outlined"
                                                    required
                                                    error={(dataForm.hasOwnProperty('numerocompte') && dataForm.hasOwnProperty('numerocompteconfirm')) &&!compareStrings(dataForm.numerocompte,dataForm.numerocompteconfirm)}
                                                    style={{marginRight:20}}
                                                />
                                           
                                          
                                                <Field
                                                    component={CustomInput}
                                                    label="Confirmation du N° compte"
                                                    id="numerocompteconfirm"
                                                    name="numerocompteconfirm"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    InputProps={{
                                                        inputProps: { max: 12 },
                                                    }}
                                                    variant="outlined"
                                                    error={(dataForm.hasOwnProperty('numerocompte') && dataForm.hasOwnProperty('numerocompteconfirm')) &&!compareStrings(dataForm.numerocompte,dataForm.numerocompteconfirm)}
                                                    required
                                                />
                                          
                                               
                                               
                                              
                                            </div>
                                </GridContainer>)}

                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Cumul des engagements</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {contratsconnexe && (<div style={{ display: "flex", flexDirection: "column", backgroundColor: "#f2f2f2", width: '100%' }}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Id</TableCell>
                                            <TableCell align="right">N° Police</TableCell>
                                            <TableCell align="right">Produit</TableCell>
                                            <TableCell align="right">Prime</TableCell>
                                            <TableCell align="right">Date effet</TableCell>
                                            <TableCell align="right">Date échéance</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {contratsconnexe.map((conex) => (
                                            <TableRow key={conex.IdProposition}>
                                                <TableCell component="th" scope="row">
                                                    {conex.IdProposition}
                                                </TableCell>
                                                <TableCell align="right">{conex.CodePolice}</TableCell>
                                                <TableCell align="right">{conex['Produit'] || ""}</TableCell>
                                                <TableCell align="right">{conex['TotalPrime'] || ""}</TableCell>
                                                <TableCell align="right">{conex['DateEffetReel'] || ""}</TableCell>
                                                <TableCell align="right">{conex['FinAdhesion'] || ""}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>)}
                    </AccordionDetails>
                </Accordion>


                <Divider style={{ marginTop: 20 }} />
                <div style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.btnback}>
                        Retour
                    </Button>
                    <Button disabled={activeStep === 0} onClick={handleSubmit(handleAttente)} className={classes.button} variant="contained" color="info">
                        Mettre en attente
                    </Button>
                    <Button disabled={!isValide} variant="contained" type='submit' className={classes.btnnext} large>
                        {activeStep === steps.length - 1 ? 'Valider' : 'Suivant'}
                    </Button>
                </div>

            </Paper>
        </form>
    )
}
const selector = formValueSelector('addPret')
const mapStateToProps = (state) => {
    const { duree, dateEcheanceFin, prime, dateEcheanceDebut, dateNaissanceSous, questionnaires, montantpret, numerocompte } = selector(state, 'duree', 'prime', 'dateEcheanceFin', 'dateEcheanceDebut', 'dateNaissanceSous', 'questionnaires', 'montantpret', 'numerocompte')
    return {
        api: state.api,
        user: state.user,
        ui: state.UI,
        duree,
        dateEcheanceFin,
        dateEcheanceDebut,
        dateNaissanceSous,
        questionnaires,
        montantpret,
        numerocompte,
        prime,
        dataForm: getFormValues('addPret')(state),
        initialValues: state.form.addPret.values
    }
};
const updateField = (field, data) => (dispatch) => dispatch(change('addPret', field, data))
const mapActionsToProps = {
    updateField,
}
export default connect(mapStateToProps, mapActionsToProps)(reduxForm({ form: 'addPret', destroyOnUnmount: false })(InfosPret))