import React,{useState,useEffect, useCallback} from 'react'
import { Paper,Tooltip,IconButton,Typography,Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete'
import { Field, reduxForm,change,registerField,getFormValues } from 'redux-form'
import {connect} from 'react-redux'
import {getGarantiesProduit,getGarantiesAssure,getPrimesCapital,getCapitauxGaranies} from 'redux/actions/apiActions'
import {ageAssure} from 'redux/actions/userActions'
import clsx from 'clsx';
import dayjs from 'dayjs'
import ModalLoading from 'components/ModalLoading';
import FormAssure from './FromAssure'
import CachedIcon from '@material-ui/icons/Cached';
import {formatNumber} from 'application'
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'

const useStyle = makeStyles(theme=>({
  bcontainer:{
    paddingTop:10,
    backgroundColor:'transparent',
    borderRadius:0
  },
  bPaper:{
    padding:10,
    borderRadius:10
  },
  btnContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:20
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  bhead:{
    padding:10,
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  assureInfoRow:{
    display:'flex',
    justifyContent:'space-between'
  },
}))

function StepAssure (props) {
  const { handleNext,data,prodGaranties,pristine,reloadAs,submitting,handleBack,steps,activeStep} = props
  const classes =useStyle()
  const [loading,setLoading] =useState(false)
  const [errors,setErrors]= useState(null)
  const [checked,setChecked] =useState([])  
  const [mesAssures,setMesAssures] = useState((data && data.hasOwnProperty('assures')?(data.assures!==undefined?data.assures:[]):[]))
  const [mesGaranties, setMesGaranties] = useState(prodGaranties?prodGaranties:[])
  const [moimemeAssure, setMoimemeAssure] = React.useState(false);
  const [modeOpen,setModeOpen] = React.useState('autreAssure')

  const deleteAssure =(assure)=>{
    let crurrentAsuree = [...mesAssures]
    let garantiesDel =[]
    assure.garantiesSouscrits.filter((item)=>{
        garantiesDel.push(item)
    })

    const garantieDispo = garantiesDel.filter(gard=>{return mesGaranties.findIndex(el=>el.CodeProduitGarantie==gard.CodeProduitGarantie)=== -1})[0]

    crurrentAsuree.garantiesFacultatives=[]    
    let newAssure = crurrentAsuree.filter((item=>item.monIndex!==assure.monIndex))
    props.updateField('assures',newAssure)   
    setMesAssures(newAssure)
    if(garantieDispo){
      setMesGaranties([...mesGaranties,garantieDispo])
    }
}
  const handleInitilaiseGarantie = ()=>{
    if (data && data.hasOwnProperty('produit')) {
      props.getGarantiesProduit(data.produit.CodeProduit).then((res) => {
          if (res) {
            setMesGaranties(res)
            props.setInitAss(true)
          }else{
            props.setInitAss(false)
          }
      })
    }
  }
  useEffect(()=>{
    if (data && data.hasOwnProperty('produit')) {
      props.getGarantiesProduit(data.produit.CodeProduit).then((res) => {
          if (res) {
              setMesGaranties(res)
          }
      })
    }
  },[data.produit])
 
  const affectationDuree=(dureeCotisationMin,dureeCotisationMax,dureeContratMin,dureeContratMax,optionnelle)=>{ 
   
  
    //durée cotisation mini
    if(!data.hasOwnProperty('dureecontratmin')){
      props.dispatch(registerField("adForm",'dureecontratmin','Field'));
    }else{
      if(optionnelle && (parseInt(dureeContratMin) >parseInt(data.dureecontratmin))){dureeContratMin=props.data.dureecontratmin}
    }
    
    
    props.updateField('dureecontratmin',dureeContratMin)

    //durée cotisation maximum
    if(!data.hasOwnProperty('dureecontratmax')){
      props.dispatch(registerField("adForm",'dureecontratmax','Field'));
    }else{
      if(optionnelle && (parseInt(dureeContratMax) <parseInt(props.data.dureecontratmax))){dureeContratMax=props.data.dureecontratmax}
    }

    props.updateField('dureecontratmax',dureeContratMax)
    //duré cotisation mini
    if(!props.data.hasOwnProperty('dureecotisationmin')){
      props.dispatch(registerField("adForm",'dureecotisationmin','Field'));
      }else{
        if(optionnelle && (parseInt(dureeCotisationMin) >parseInt(props.data.dureecotisationmin))){dureeCotisationMin=props.data.dureecotisationmin}
      }
      props.updateField('dureecotisationmin',dureeCotisationMin)
      //durée cotisation max
      if(!props.data.hasOwnProperty('dureecotisationmax')){
        props.dispatch(registerField("adForm",'dureecotisationmax','Field'));
      }else{
        if(optionnelle && (parseInt(dureeCotisationMax) <parseInt(props.data.dureecotisationmax))){dureeCotisationMax=props.data.dureecotisationmax}
      }
      props.updateField('dureecotisationmax', dureeCotisationMax)
                
  }

  const addAssure =(defaultData,moimeme)=>{
   
      setLoading(true)
      const {getGarantiesAssure}=props
       //verification de la présence de l'assuré
      if(mesAssures && mesAssures.length!==0){
        let estPresent = false
        if(estPresent){
          setLoading(false)
          setErrors({type:'warning',message:'Cet assuré existe déjà'})
          return false
        }
      }
  
      //vérification de la présence des garanties
      if(mesGaranties && mesGaranties.length===0){
        setLoading(false)
        setErrors({type:'error',message:'Aucune garanties trouvées'})
        return false
      }
     
      //vérification de la présence des information sur l'assurée
      if(!defaultData){
        setLoading(false)
        setErrors({type:'error',message:'Erreur sur l\'assurée'})
        return false
      }
     
      //selection des garanties
       let dureeCotisationMin=undefined
       let dureeCotisationMax=undefined
       let dureeContratMin=undefined
       let dureeContratMax=undefined
     
       let garantiesObligatoires
       let garantiesComplementaires
       let garantiesCompFacultatif
       let codeproduit
       let dateNaissance
       codeproduit = data.produit.CodeProduit
       dateNaissance = moimeme?data.dateNaissanceSous:defaultData.dateNaissanceAssure

       let garantiesFree =[]
      
       let localGaranties =Array.from(mesGaranties)
      
      //récupération des garanties pour l'assuré
   
      getGarantiesAssure(codeproduit,ageAssure(dayjs(dateNaissance).format('YYYY-MM-DD')),defaultData['typeGarantie']||null).then(async (garanties)=>{
       
        if(garanties){ 

          garantiesObligatoires =garanties.filter(item=>item.estobligatoire==1)
          garantiesCompFacultatif = garanties.filter(item=>(item.estobligatoire==0 && item.estcomplementaire==0))
          garantiesComplementaires = garanties.filter(item=>(item.estobligatoire==0 && item.estcomplementaire==1))
          
          let garantiesParcourru
          defaultData.garantiesFacultatives=[]
          if((garantiesObligatoires && garantiesObligatoires.length!==0) || (garantiesCompFacultatif && garantiesCompFacultatif.length!==0)){ 
            
            let result = false
            let newGarantieOb =[]

            newGarantieOb = garantiesObligatoires.filter(garO=>{return localGaranties.findIndex(el=>el.CodeProduitGarantie==garO.codeproduitgarantie)!== -1})
            garantiesObligatoires = newGarantieOb 
            garantiesParcourru = (garantiesObligatoires && garantiesObligatoires.length!==0) ? garantiesObligatoires:garantiesCompFacultatif
            
            const garAssure = ()=>(
              localGaranties.map(async (lg)=>{
                return await Promise.all(garantiesParcourru.map(async (element) => { 
                  if(element.codeproduitgarantie===lg.CodeProduitGarantie){
                    lg.estunique = element.estunique
                    lg.description = element.description
                    lg.checked=true
                    lg.agemin = element.agemin
                    lg.agemax = element.agemax
                    lg.dureecotisationmin = element.dureecotisationmin
                    lg.dureecotisationmax = element.dureecotisationmax
                    lg.dureecontratmin = element.dureecontratmin
                    lg.dureecontratmax = element.dureecontratmax
                    lg.primemin = element.primemin
                    lg.montantgarantie = element.montantgarantie
                    lg.type = element.type
                    lg.estobligatoire = element.estobligatoire
                    lg.estcomplementaire = element.estcomplementaire
                    lg.estprincipal =  element.estprincipal
                    lg.branche =  element.branche
                    if(element.type==='Decces' || element.type==='Deces'){
                      lg.capitaux =await getPrimesCapital(props.data.produit.CodeProduit,lg.CodeProduitGarantie,props.data.produit.CodeProduitFormule)
                    }else{
                      lg.capitaux = await getCapitauxGaranies(props.data.produit.CodeProduit,lg.CodeProduitGarantie)
                    }
                    if(dureeCotisationMin!==undefined && element.dureecotisationmin<dureeCotisationMin) {
                      dureeCotisationMin = element.dureecotisationmin
                    }else{
                      dureeCotisationMin = element.dureecotisationmin
                    }
                    if(dureeCotisationMax!==undefined && element.dureecotisationmax>dureeCotisationMax) {
                      dureeCotisationMax = element.dureecotisationmax
                    }else{
                      dureeCotisationMax = element.dureecotisationmax
                    }
                    if(dureeContratMin!==undefined && element.dureecontratmin<dureeContratMin) {
                      dureeContratMin = element.dureecontratmin
                    }else{
                      dureeContratMin = element.dureecontratmin
                    }
                    if(dureeContratMax!== undefined && element.dureecontratmax>dureeContratMax) {
                      dureeContratMax = element.dureecontratmax
                    }else{
                      dureeContratMax = element.dureecontratmax
                    }
                    
                    //liberer garantie
                    if(element.estunique==1){
                      garantiesFree.push(lg.CodeProduitGarantie)
                    }
                  
                    if(props.data.produit.CodeProduit ==='YKL_2004' || props.data.produit.CodeProduit ==='YKV_2004' || props.data.produit.CodeProduit ==='YKF_2004'){
                      const ageAss =parseInt(ageAssure(dayjs(dateNaissance).format('YYYY-MM-DD')))
                      const maDuree = (70-ageAss)
                      if(maDuree){
                        dureeCotisationMin=maDuree
                        dureeCotisationMax=maDuree
                        dureeContratMin=maDuree
                        dureeContratMax=maDuree
                      }
                    }
                    element.estprincipal==1&& affectationDuree(dureeCotisationMin,dureeCotisationMax,dureeContratMin,dureeContratMax,false)
                    return lg
                  }
                  return null
                }))
              })
            )
              
            const garcom = ()=>{
              if(garantiesComplementaires && garantiesComplementaires.length!==0){
                return localGaranties.map(async(lg)=>{
                  return await Promise.all(garantiesComplementaires.map(async (element) => {
                    lg.checked=false
                    lg.estunique = element.estunique
                    lg.description = element.description
                    lg.agemin = element.agemin
                    lg.agemax = element.agemax
                    lg.dureecotisationmin = element.dureecotisationmin
                    lg.dureecotisationmax = element.dureecotisationmax
                    lg.dureecontratmin = element.dureecontratmin
                    lg.dureecontratmax = element.dureecontratmax
                    lg.dureecontratmax = element.dureecontratmax
                    lg.primemin = element.primemin
                    lg.montantgarantie = element.montantgarantie
                    lg.type = element.type
                    lg.estobligatoire = element.estobligatoire
                    lg.estcomplementaire = element.estcomplementaire
                    lg.estprincipal =  element.estprincipal
                    lg.branche =  element.branche
                    if(element.type==='Decces' || element.type==='Deces'){
                      lg.capitaux = await getPrimesCapital(props.data.produit.CodeProduit,lg.CodeProduitGarantie,props.data.produit.CodeProduitFormule)
                   
                    }else{
                      lg.capitaux = await getCapitauxGaranies(props.data.produit.CodeProduit,lg.CodeProduitGarantie)
                    }
                    if(element.estunique==1){
                      garantiesFree.push(lg.CodeProduitGarantie)
                    }
                    return lg
                  }))
                })
              }
            }
            const garFac = await garcom()
            await Promise.all(await garAssure()).then(myas=>{
              const  currentgar = myas?myas.map(c=>c.filter(item=>item!==null)).filter(({ length }) => length!==0)[0]:[]
              const  currentgarFac = garFac?garFac.map(c=>c.filter(item=>item!==null)).filter(({ length }) => length!==0)[0]:[]
              defaultData['garantiesSouscrits'] = currentgar
              defaultData['garantiesFacultatives'] = currentgarFac
              defaultData.monIndex = (mesAssures.length+1)
              let newAssure = [...mesAssures,defaultData]
              props.updateField('assures',newAssure)  
              setMesAssures(newAssure)
              //réduction des garnties disponible
              setLoading(false)
              if(garantiesFree && garantiesFree.length!==0){
                setMesGaranties(localGaranties.filter(free=>{return !garantiesFree.includes(free.CodeProduitGarantie)}))
              }
            }).catch(err=>{
              console.log(err);
              setLoading(false)
            })
          }else{
            setLoading(false)
            setErrors({type:'error',message:'Aucune garanties trouvée'})
            return false
          }
        }else{
          setLoading(false)
          setErrors({type:'error',message:'Aucune garanties trouvée'})
          return false
        }
        
      }).catch((err)=>{
        console.log(err);
        setLoading(false)
        setErrors({type:'error',message:'Erreur lors de la récupération des garanties'})
        return false
      });
  }

  const handleMoimemeAssure =()=>{
    const moimeme =!moimemeAssure;
    let mesdonne={}
    setMoimemeAssure(moimeme)
   
    if(moimeme){
        mesdonne.nomAssure =data.nomSous && data.nomSous 
        mesdonne.prenomAssure =data.prenomSous && data.prenomSous
        mesdonne.dateNaissanceAssure =data.dateNaissanceSous && dayjs(data.dateNaissanceSous).format('YYYY-MM-DD')
        mesdonne.lieuNaissanceAssure =data.lieuNaissanceSous && data.lieuNaissanceSous
        mesdonne.numeroCniAssure =data.pieceIdentiteSous && data.pieceIdentiteSous
        mesdonne.lieuResidenceAssure =data.lieuResidenceSous && data.lieuResidenceSous
        mesdonne.filiationAssure ='Moi même'
        mesdonne.emailAssure =data.emailSous && data.emailSous
        mesdonne.telephoneAssure =data.mobileSous && data.mobileSous
        mesdonne.monIndex = mesAssures?(mesAssures.length+1):1
        
        addAssure(mesdonne,true)
        props.setInitAss(false)
    }
  }
  
  const handleReloadAssure = useCallback(()=>{
    handleMoimemeAssure()
  },[data.dateNaissanceSous])

  useEffect(()=>{
    if(mesAssures && mesAssures.length===0 && reloadAs){
      handleReloadAssure()
    } 
  },[reloadAs])
 
  const [open, setOpen] = React.useState(false);
  const handleOpen = (mode) => {
    setModeOpen(mode)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddAssure =(mesdonne)=>{
    if(mesdonne){
      addAssure(mesdonne,false)
      handleClose()
    }
  }
 useEffect(()=>{
    if(!data.hasOwnProperty('assures')){
      props.dispatch(registerField("adForm",'assures','Field'));
    }
    props.updateField('assures',mesAssures)
 },[mesAssures])

 const handleResetAssure = ()=>{
    setMesAssures([])
    setMesGaranties([])
    props.updateField('assures',null)
    handleInitilaiseGarantie()
    handleMoimemeAssure()
 }
 useEffect(()=>{
  if(!data.hasOwnProperty('capitalSouscrit') && (mesAssures && mesAssures.length!==0)){
    mesAssures.map(ass=>{
      if(ass.garantiesSouscrits  && ass.garantiesSouscrits.length!==0){
        let name
        for( const gar of ass.garantiesSouscrits){
           name = `capital-${gar.CodeProduitGarantie}`
          if(gar.estprincipal && data.hasOwnProperty(name)){
            props.dispatch(registerField("adForm",`capitalSouscrit`,'Field'));
            props.updateField(`capitalSouscrit`,data[name])
          }
        }
      }
    })
  }
 })

  return (
    <div>
        {loading&&<ModalLoading myopen={loading} />}
        <FormAssure 
          handleClose={handleClose} 
          handleOpen={handleOpen} 
          open={open} 
          handleAddAssure={handleAddAssure}  
          mesAssures={mesAssures}
          mesGaranties={mesGaranties} 
          modeOpen={modeOpen}
        />
      <Paper className={classes.bcontainer} variant="outlined">
          <div className={classes.bhead}>
              <Typography variant='h5'>Liste des assurées</Typography>
              <Tooltip title="Réinitialiser"> 
                <IconButton color='primary' onClick={handleResetAssure}>
                    <CachedIcon style={{fontSize:30}} />
                </IconButton>
            </Tooltip>
              <Tooltip title="Ajouter un autre assuré"> 
                <IconButton color='primary' onClick={()=>handleOpen('autreAssure')}>
                    <PersonAddIcon style={{fontSize:30}} />
                    Ajouter un assuré
                </IconButton>
            </Tooltip>
          </div>
          <Paper variant="outlined">
              {(mesAssures && mesAssures.length!==0)? (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Assuré(e)</TableCell>
                        <TableCell align="center">Garanties</TableCell>
                        <TableCell align="center">Capital</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {mesAssures.map((assure,index) => { 
                        return (
                        <TableRow key={assure.numeroCniAssure}>
                          <TableCell scope="row">
                              <Typography variant="h6">{`${assure.nomAssure} ${assure.prenomAssure}`}</Typography>
                              <div className={classes.assureInfoRow}><Typography>N° CNI : </Typography><Typography variant="subtitle1" color="textSecondary"> {` ${assure.numeroCniAssure} `}</Typography></div>
                              <div className={classes.assureInfoRow}>
                                <Typography>Né(e) le : </Typography><Typography variant="subtitle1" color="textSecondary"> {` ${dayjs(assure.dateNaissanceAssure).format('DD/MM/YYYY')} `}</Typography>
                              </div>
                          </TableCell>
                          <TableCell align="left">
                            {
                              (assure.garantiesSouscrits && assure.garantiesSouscrits.length!==0) && assure.garantiesSouscrits.map((garantie,index)=>(
                              <Typography key={index}> - {garantie.MonLibelle +'\n'}</Typography> 
                              ))
                            }
                            </TableCell>
                            <TableCell align="center">
                            {(assure.garantiesSouscrits && assure.garantiesSouscrits.length!==0)&& <>
                              {assure.garantiesSouscrits.map((garantie,idx)=>{
                                let capitalShow = (garantie.hasOwnProperty('capitaux') && garantie.capitaux && garantie.capitaux.length!==0)?garantie.capitaux.map(cap=>(parseInt(cap.capital))):[]
                               
                                if(garantie.hasOwnProperty('capitaux') && garantie.capitaux && garantie.capitaux.length===1){
                                  if(!data.hasOwnProperty(`capital-${garantie.CodeProduitGarantie}`)){
                                    props.dispatch(registerField("adForm",`capital-${garantie.CodeProduitGarantie}`,'Field'));
                                  }
                                  props.updateField(`capital-${garantie.CodeProduitGarantie}`,garantie.capitaux[0].capital)
                                }
                                return <Field
                                  id={`capital-${garantie.CodeProduitGarantie}`}
                                  name={`capital-${garantie.CodeProduitGarantie}`}
                                  component={CustomSimpleSelect}      
                                  formControlProps={{
                                    fullWidth: true,      
                                  }}
                                  variant="outlined"
                                  options={capitalShow}
                              />   
                                })
                            }</>}
                            </TableCell>
                            
                          <TableCell align="right">
                            <IconButton
                              data-id={assure.numeroCniAssure}
                              aria-label="actions"
                              aria-haspopup="true"
                              onClick={()=>deleteAssure(assure)}
                            >
                            <DeleteIcon />
                            </IconButton>
                            
                            </TableCell>
                        </TableRow>
                      
                    )})}
                    </TableBody>
                  </Table>
                </TableContainer>):(<Typography>Aucun Assuré </Typography>)}
              </Paper>
              <div className={classes.btnContainer}>
                    <Button onClick={handleBack} className={classes.btnback}>Retour</Button>
                    <Button type='submit' disabled={ (data.hasOwnProperty('assures') && data.assures.length===0)} onClick={handleNext}  className={classes.btnnext}>Continuer</Button>
              </div>
      </Paper>
      
    </div>
  )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
  updateField,
  getGarantiesProduit,
  getGarantiesAssure
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state),
    ui: state.UI
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
})(StepAssure))
