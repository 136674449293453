import React, { useReducer, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import { connect } from 'react-redux'
import CustomInput from 'components/CustomInput/CustomInput'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import TextField from '@material-ui/core/TextField';
import { addUtilisateur, getEquipesZones, getZonesReseau, getRolesReseau } from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { validateSettingUtilisateur } from 'util/validators'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import dayjs from 'dayjs'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Divider, Paper, Tooltip } from '@material-ui/core';
import swal from 'sweetalert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

const useStyles = makeStyles((theme) => ({
    root:{
        display:'flex',
        flexDirection:'column',
        width:'75%',
        marginLeft:'auto',
        marginRight:'auto',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.up('md')]: {
        width:'75%',
        },
        marginBottom:20
    },
    hPaper:{
        padding:10,
        marginBottom:20,
        borderLeftColor: theme.palette.primary.main,
        borderLeftWidth:8
    },
    bPaper:{
      padding:10,
      marginBottom:20,
      borderRadius:0
  },
    hContainer:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    loaddingContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
    },
    bcontainer:{
      paddingTop:10,
      backgroundColor:'transparent',
      borderRadius:0
    },
    bhead:{
        padding:10
    }
}));

function AddUtilisateur(props) {
    const classes = useStyles();
    //intialisation des élement du state
    const {settings: { reseaux } } = props //recuperation des valieur du props
    const [zones, setZones] = useState([])
    const [equipes, setEquipes] = useState([])
    const [rolesReseau, setRolesReseau] = useState([])
    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            nom: "",
            prenom: "",
            datenaissance: "",
            sexe: "",
            telephone: '',
            telephone2: '',
            email: '',
            login: '',
            password: '',
            passwordConfirm: '',
            codezone: '',
            codeequipe: '',
            codereseau: '',
            coderole: '',
            codeagent: '',
            profession: ''
        });
    const [loading, setLoading] = useState(false)
  
    const handleChange = (event) => {
        const { name, value } = event.target
        setInputField({
            [name]: value
        })
    }
    const handleChangePhone = (value, name) => {
        setInputField({
            [name]: value
        })
    }
    useEffect(() => {
        if (inputField.codereseau !== '' && zones.length === 0) {
            getZonesReseau(inputField.codereseau).then(dataZone => {
                setZones(dataZone)
            })
        }
        if (inputField.codereseau !== '' && rolesReseau.length === 0) {
            getRolesReseau(4).then(dataRole => {
                setRolesReseau(dataRole)
            })
        }
        if (inputField.codezone !== '' && equipes.length === 0) {
            getEquipesZones(inputField.codezone).then(dataEquipe => {
                setEquipes(dataEquipe)
            })
        }
    })
    const handleSaveUtilisateur = (event) => {
        event.preventDefault()
        const { valid, errors } = validateSettingUtilisateur(inputField)
      
        if (!valid) {
            swal("Attention !",`Veuillez remplir les champs obligatoires\n${errors.join()}`,"warning")
            return
        }
        setLoading(true)
        props.addUtilisateur(inputField).then(dataUser => {
            if (dataUser) {
                setInputField({
                    ['nom']: '',
                    ['prenom']: '',
                    ['login']: '',
                    ['password']: '',
                    ['passwordConfirm']: '',
                    ['datenaissance']: "",
                    ['telephone']: '',
                    ['telephone2']: '',
                    ['email']: '',
                    ['profession']: ''
                })
                setLoading(false)
                swal("Bien !","Enregistrement effectué avec succès","success")
            } else {
                setLoading(false)
                swal("Ooops !","Erreur lors de l'enregistrement","error")
                return false
            }
            return
        }).catch(err => {
            console.log(err);
            setLoading(false)
            swal("Ooops!",err,"error")
            return
        })
        event.stopPropagation()
        return
    }
    const [tabs,setTabs] = useState('compte')
    const handleTabs = (tb)=>{
        setTabs(tb)
    }
    return (
        <div className={classes.root}>
            <Paper className={classes.hPaper} variant="outlined">
                <div className='flex flex-row items-center'>
                    <Tooltip title="Nouvelle branche"> 
                        <IconButton onClick={()=>props.history.push(`/settings/utilisateur`)} color='primary'>
                            <ArrowBackIcon style={{fontSize:40}} />
                        </IconButton>
                    </Tooltip>
                    <Typography variant='h5'>Creer un nouvel utilisateur</Typography>
                
                </div>
            </Paper>
                <form onSubmit={handleSaveUtilisateur}>
                    {!loading ? (
                  
                         <Paper  variant="outlined">
                            <div className='flex flex-row'>
                                <Button  onClick={()=>handleTabs('compte')} className={`p-2 px-4 ${tabs==='compte' ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}><AccountCircleIcon /> Comptes</Button>
                                <Button  onClick={()=>handleTabs('infos')} className={`p-2 px-4 ${tabs==='infos' ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}><AssignmentIndIcon />Informations personnelles</Button>
                                <Button  onClick={()=>handleTabs('contacts')} className={`p-2 px-4 ${tabs==='contacts' ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}><ContactPhoneIcon/>Contacts</Button>
                                <Button  onClick={()=>handleTabs('reseau')} className={`p-2 px-4 ${tabs==='reseau' ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}><AccountTreeIcon/>Reseau</Button>
                            </div>
                          <Divider />

                          {tabs==='compte'&&(
                            <div className='p-3 max-w-screen-sm mx-auto rounded-md mb-3'>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="email"
                                    name='email'
                                    onChange={handleChange}
                                    label="Email"
                                    type="email"
                                    value={inputField.email}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="login"
                                    name='login'
                                    label="Nom utilisateur (login)"
                                    type="text"
                                    value={inputField.login}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    id="coderole"
                                    name='coderole'
                                    select
                                    label="Profile"
                                    value={inputField.coderole}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ marginTop: 20, marginBottom: 10 }}
                                >
                                    <MenuItem key={0} value={0} />
                                    {(rolesReseau.length !== 0) && rolesReseau.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.role}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                required
                                autoFocus
                                variant="outlined"
                                id="password"
                                name='password'
                                label="Mot de passe"
                                type="password"
                                value={inputField.password}
                                fullWidth
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ marginTop: 20, marginBottom: 10 }}
                                    />
                               
                                <TextField
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="passwordConfirm"
                                    name='passwordConfirm'
                                    label="Confirmation du mot de passe"
                                    type="password"
                                    value={inputField.passwordConfirm}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    style={{ marginTop: 20, marginBottom: 10 }}
                                />
                                
                            </div>
                          )}
                         {tabs==='infos'&&(
                            <div className='p-3 max-w-screen-sm mx-auto rounded-md mb-3'>
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="nom"
                                    name='nom'
                                    label="Nom"
                                    type="text"
                                    value={inputField.nom}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                                  
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="prenom"
                                    name='prenom'
                                    onChange={handleChange}
                                    label="Prénom"
                                    type="text"
                                    value={inputField.prenom}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                                  
                                <TextField
                                    required
                                    variant="outlined"
                                    id="datenaissance"
                                    name='datenaissance'
                                    onChange={handleChange}
                                    label="Date de naissence"
                                    value={inputField.datenaissance}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        type: 'date',
                                        inputProps: { min: dayjs().add(-100, 'year').format('YYYY-MM-DD'), max: dayjs().add(-12, 'year').format('YYYY-MM-DD') },
                                    }}
                                    style={{ marginTop: 20, marginBottom: 10 }}
                                />
                                    
                                <CustomRadio
                                    required
                                    row
                                    variant="outlined"
                                    id="sexe"
                                    name='sexe'
                                    onChange={handleChange}
                                    label="Sexe"
                                    value={inputField.sexe}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    options={[{ "label": "M", "value": "M" }, { "label": "F", "value": "F" }]}
                                />
                    
                                <CustomInput
                                    autoFocus
                                    variant="outlined"
                                    id="profession"
                                    name='profession'
                                    label="Profession"
                                    type="text"
                                    value={inputField.profession}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                            </div>
                          )}
                         {tabs==='contacts'&&(<div className='p-3 max-w-screen-sm mx-auto rounded-md mb-3'>
                                <CustomPhoneInput
                                    label="Mobile 1"
                                    id="telephone"
                                    name="telephone"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={(v) => handleChangePhone(v, 'telephone')}
                                    value={inputField.telephone}
                                    variant="outlined"
                                /> 
                                <CustomPhoneInput
                                    label="Mobile 2"
                                    id="telephone2"
                                    name="telephone2"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={(v) => handleChangePhone(v, 'telephone2')}
                                    value={inputField.telephone2}
                                    variant="outlined"
                                />
                         </div>)}
                         {tabs==='reseau'&&(<div className='p-3 max-w-screen-sm mx-auto rounded-md mb-3'>
                            <CustomInput
                                required
                                autoFocus
                                variant="outlined"
                                id="codeagent"
                                name='codeagent'
                                label="Code agent"
                                type="text"
                                value={inputField.codeagent}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                id="codereseau"
                                name='codereseau'
                                select
                                label="Réseau de commercialisation"
                                value={inputField.codereseau}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ marginTop: 20, marginBottom: 10 }}
                            >
                                <MenuItem key={0} value={0} />
                                {(reseaux.length !== 0) && reseaux.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.libelle}
                                    </MenuItem>
                                ))}
                            </TextField>
                                   
                            <TextField
                                variant="outlined"
                                id="codezone"
                                name='codezone'
                                select
                                label="Zone/Departement"
                                value={inputField.codezone}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ marginTop: 20, marginBottom: 10 }}
                            >
                                <MenuItem key={0} value={0} />
                                {(zones.length !== 0) && zones.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.libellezone}
                                    </MenuItem>
                                ))}
                            </TextField>    
                            <TextField
                                variant="outlined"
                                id="codeequipe"
                                name='codeequipe'
                                select
                                label="Equipe/Agence"
                                value={inputField.codeequipe}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ marginTop: 20, marginBottom: 10 }}
                            >
                                <MenuItem key={0} value={0} />
                                {(equipes.length !== 0) && equipes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.libelleequipe}
                                    </MenuItem>
                                ))}
                            </TextField>
                         </div>)}
                        <Divider />
                        <div className='my-4 flex flex-row justify-between items-center px-2'>
                            <Button className='py-2 px-4 bg-secondary-main rounded-full shadow-md border-0 text-white' variant='outlined' type='reset'>Annuler</Button>
                            <Button className='py-2 px-4 bg-primary-main rounded-full shadow-md border-0 text-white' variant='outlined' type='submit'>Enregistrer</Button>
                        </div>

                        </Paper>) : (<div className={classes.root}><CircularProgress color="primary" /></div>)}

                </form>
      
        </div>
    )
}
AddUtilisateur.propTypes = {
    mopen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    settings: state.settings
});
const mapActionsToProps = { addUtilisateur }
export default connect(mapStateToProps, mapActionsToProps)(AddUtilisateur)