import React from 'react';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { ageAssure, ageBorne } from 'redux/actions/userActions'
import dayjs from 'dayjs'
import { selectGarantiesContrat, selectAssuresContrat, selectBeneficairesContrat } from 'redux/actions/apiActions'
import { renderToString } from 'react-dom/server';
const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
const styles = {
  table: {
    width: '100%',
    color: '#717375',
    backgroundColor: '#fff',
    fontFamily: 'helvetica'
  },
  td: {
    textAlign: "center"
  }
  
}
const Prints = () => (
  <div>
    <table style={styles.table}>
      <tr><td style={styles.td}><h1>RESUME CONDITIONS GENERALES</h1></td></tr>

    </table>
  </div>
)

export const generateBulletinSouscription = async (data, credentials) => {

  const headRows1 = () => ([{ libelle: 'Libelle', valeur: 'Valeur' }])
 
  
  let doc = new jsPDF();
  doc.setProperties({
    title: 'Bulletin de souscription',
    author: 'Benoit',
    creator: 'YAKO AFRICA'
  });

  let img = new Image(35, 23)
  img.src = require('assets/images/logo_yako.jpg')
  doc.addImage(img, "JPEG", 10, 5, 35, 23);
  let signature = new Image(40, 10)
  signature.src = require('assets/images/Signature_Dta.jpg')

  const { branche, partenaire, codepartenaire } = credentials

  if (branche !== 'BANKASS') {
    let logoEnov = new Image(35, 13)
    logoEnov.src = require('assets/images/logoYnovGreen_b.png')
    doc.addImage(logoEnov, "PNG", 170, 10, 35, 13);
  } else {
    let logoPart = new Image(25, 10)
    if (codepartenaire === 'AFC') {
      logoPart.src = require(`assets/images/${codepartenaire}.jpg`)
    } else {
      logoPart.src = require('assets/images/BNI.jpeg')
    }

    doc.addImage(logoPart, "JPEG", 180, 10, 20, 20);
  }

  let codeRef = data.codeproduit.substring(0, 3)
  if (partenaire && partenaire.trim() !== '') {
    codeRef = partenaire.toUpperCase() + '-' + codeRef
  }

  doc.text(80, 25, 'BULLETIN DE SOUSCRIPTION');
  doc.setLineWidth(0.1);
  doc.line(15, 30, 195, 30);
  //doc.addFont('ArialMS', 'Arial', 'bold ');
  //doc.setFont('Arial');
  //doc.setFontType('bold')
  doc.setFontSize(14)
  doc.text(80, 35, 'N° : ' + codeRef + '-' + dayjs().format('MMYYYY') + data.id)
  doc.autoTable({
    startY: 37,
    head: [{
      libelle: 'Libelle', valeur: 'Valeur',
      libelle: 'Libelle', valeur: 'Valeur',
      libelle: 'Libelle', valeur: 'Valeur'
    }],
    body: [
      ['Produit :', data.libelleproduit ? data.libelleproduit : "", 'Conseiller :', `${data.codeConseiller ? data.codeConseiller : ""} ${(data.nomagent) ? ('/' + data.nomagent) : ''}`, 'Agence :', credentials.agence ? credentials.agence : ""],
      [],
    ],
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center' },
    showHead: false,
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],

  });


  let finalY = doc.previousAutoTable.finalY

  doc.autoTable({
    startY: finalY,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'ADHERENT' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },

    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: { top: 0 },
  })


  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 1,
    head: headRows1(),
    body: [
      [`N° ${data.naturepiece ? data.naturepiece : " CNI"} : ${data.numeropiece ? data.numeropiece : ""}`],
      [`Nom : `, data.nom],
      ["Prénom : ", data.prenom],
      ["Né(e) le : ", dayjs(data.datenaissance).format('DD/MM/YYYY')],
      [" A : ", data.lieunaissance],
      ["Lieu de résidence : ", data.lieuresidence],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, halign: 'left', valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
    ],
    tableWidth: 90,
  })
  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      ["Profession :", `${(data.profession && data.profession !== 'null') ? data.profession : ""}`],
      ["Secteur d'activité : ", `${data.employeur ? data.employeur : ""}`],
      ["Email : ", `${(data.email && data.email !== 'null') ? data.email : ""}`],
      ["Téléphone : ", `${(data.telephone && data.telephone !== 'null') ? data.telephone : ""}`],
      ["Mobile : ", `${(data.mobile && data.mobile !== 'null') ? data.mobile : ""} ${(data.mobile1 && data.mobile1 !== 'null') ? "/" + data.mobile1 : ""}`],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],
    margin: { left: 105 },
    tableWidth: 105,
  })

  finalY = doc.previousAutoTable.finalY

  //Affectation des assurées
  doc.autoTable({
    startY: finalY + 10,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'ASSURES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' }
    ],
  })
  finalY = doc.previousAutoTable.finalY


  const headAssure = () => ([
    { nomAssure: 'Nom', filiationAssure: 'Filiation', dateNaissanceAssure: 'Né(e) le', lieuNaissanceAssure: 'Lieu naissance', lieuResidenceAssure: 'Lieu Résidence' }
  ]);
  let body = []
  const bodyAssure = async () => {

    const assures = await selectAssuresContrat(data.id)

    if (assures) {
      assures.map((assure) => {
        body.push({
          nomAssure: assure.nom + ' ' + assure.prenom,
          filiationAssure: assure.filiation,
          dateNaissanceAssure: dayjs(assure.datenaissance).format('DD/MM/YYYY'),
          lieuNaissanceAssure: assure.lieunaissance,
          lieuResidenceAssure: assure.lieuresidence
        })
      })
    }
    return body
  }

  doc.autoTable({
    startY: finalY + 3,
    head: headAssure(),
    body: await bodyAssure(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });
  finalY = doc.previousAutoTable.finalY

  //Affectation des bénéficiaire
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'BENEFICIAIRES / REPRESENTANTS DESIGNES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })

  finalY = doc.previousAutoTable.finalY
  const auTermeBody = async () => {

    let dataAuTerme = []

    if (data.hasOwnProperty('beneficiaireauterme') && data.beneficiaireauterme) {

      const benefAuTerme = data.beneficiaireauterme.split(';');
      if (benefAuTerme) {
        benefAuTerme.map(auterme => {
          if (auterme === 'adherent') {
            dataAuTerme.push({ "libelle": "- L'adherent" })
          }
          if (auterme === 'conjoint') {
            dataAuTerme.push({ "libelle": "- Le conjoint non divorcé, ni séparé de corps" })
          }
          if (auterme === 'enfants') {
            dataAuTerme.push({ "libelle": "- Les enfants nés et à naître" })
          }
          if (auterme === 'autre') {
            dataAuTerme.push({ "libelle": "- Autre" })
          }
        })
      }
    }
    return dataAuTerme
  }
  if (data.hasOwnProperty('beneficiaireauterme')) {

    doc.autoTable({
      startY: finalY + 3,
      head: [{ libelle: 'Au terme du contrat' }],
      body: await auTermeBody(),
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', cellPadding: 1 },
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'normal', fillColor: [245, 245, 245], },
      },
      headStyles: { fillColor: [255, 255, 255], textColor: 0, fontStyle: 'bold', valign: 'center' },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' },
      ],
      tableWidth: 75,
    })
  }

  const auDecesbody = async () => {

    let dataAuDeces = []

    if (data.hasOwnProperty('beneficiaireaudeces') && data.beneficiaireaudeces) {
      const benefAuDeces = data.beneficiaireaudeces.split(';');
      if (benefAuDeces) {
        benefAuDeces.map(audeces => {
          if (audeces === 'conjoint') {
            dataAuDeces.push({ "libelle": "- Le conjoint non divorcé, ni séparé de corps" })
          }
          if (audeces === 'enfants') {
            dataAuDeces.push({ "libelle": "- Les enfants nés et à naître" })
          }
          if (audeces === 'autre') {
            dataAuDeces.push({ "libelle": "- Autre" })
          }
        })

      }
    }
    return dataAuDeces
  }
  if (data.hasOwnProperty('beneficiaireaudeces')) {

    doc.autoTable({
      startY: finalY + 3,
      head: [{ libelle: 'En cas de décès avant le terme' }],
      body: await auDecesbody(),
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', cellPadding: 1 },
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'normal', fillColor: [255, 255, 255], }
      },
      headStyles: { fillColor: [255, 255, 255], textColor: 0, fontStyle: 'bold', valign: 'center' },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' },
      ],
      margin: { left: 105 },
      tableWidth: 75,
    })
  }

  finalY = doc.previousAutoTable.finalY
  const headBenficaire = () => ([
    { nomBeneficiaire: 'Nom', filiationBeneficiaire: 'Filiation', dateNaissanceBeneficiaire: 'Né(e) le', lieuNaissanceBeneficiaire: 'Lieu naissance', telephoneBeneficiaire: 'Téléphone' }
  ]);
  const bodyBeneficaire = async () => {
    let body = []
    const beneficiaires = await selectBeneficairesContrat(data.id)

    if (beneficiaires) {
      beneficiaires.map((beneficiaire) => {
        body.push({
          nomBeneficiaire: beneficiaire.nom + ' ' + beneficiaire.prenom,
          filiationBeneficiaire: beneficiaire.filiation,
          dateNaissanceBeneficiaire: dayjs(beneficiaire.datenaissance).format('DD/MM/YYYY'),
          lieuNaissanceBeneficiaire: beneficiaire.lieunaissance,
          telephoneBeneficiaire: beneficiaire.mobile
        })
      })
    }
    return body
  }
  doc.autoTable({
    startY: finalY + 3,
    head: headBenficaire(),
    body: await bodyBeneficaire(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });

  //Assuré garantie
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'GARANTIES & PRIMES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' }
    ],
  })
  finalY = doc.previousAutoTable.finalY

  let headGaranties = () => ([
    { nomAssure: 'Nom', garantie: 'Garantie', capitalGarantie: 'Capital', primeGarantie: 'Prime', primeAccesoire: 'Accéssoire', totalprime: 'Total prime' }
  ]);

  let bodyGaranties = async () => {
    let body = []
    let garanties = await selectGarantiesContrat(data.id)

    if (garanties) {
      garanties.map((garantie) => {
        body.push({
          nomAssure: garantie.nom + ' ' + garantie.prenom,
          garantie: garantie.monlibelle,
          capitalGarantie: garantie.capitalgarantie ? formatNumber(parseInt(garantie.capitalgarantie)) : '',
          primeGarantie: garantie.prime ? formatNumber(parseInt(garantie.prime)) : '',
          primeAccesoire: garantie.primeaccesoire ? formatNumber(garantie.primeaccesoire) : '',
          totalprime: garantie.primetotal ? formatNumber(garantie.primetotal) : ''
        })
      })
    }



    return body
  }

  if (data.codeproduit !== 'PVRBNI') {
    headGaranties = () => ([
      { garantie: 'Garantie', option: 'Option/Capital', primeGarantie: 'Prime', periodicite: 'Périodicité', totalprime: 'Total prime' }
    ])

    bodyGaranties = async () => {
      let body = []
      let garanties = await selectGarantiesContrat(data.id)

      //choix de l'option
      let option = ""
      let maPeriodicite = ''
      if (garanties) {
        garanties.map((garantie) => {

          if (data.codeproduit === 'YKV_2004' || data.codeproduit === 'YKL_2004' || data.codeproduit === 'YKF_2004') {
            if (garantie.codeproduitgarantie !== 'REMB') {
              if (parseInt(garantie.capitalgarantie) === 430000) {
                option = "HONNEUR"
              } else if (parseInt(garantie.capitalgarantie) === 750000) {
                option = "ELITE"
              } else if (parseInt(garantie.capitalgarantie) === 1400000) {
                option = "PRESTIGE"
              } else {
                option = formatNumber(parseInt(garantie.capitalgarantie))
              }
            } else {
              option = garantie.capitalgarantie ? formatNumber(parseInt(garantie.capitalgarantie)) : ''
            }
          } else {
            option = garantie.capitalgarantie ? formatNumber(parseInt(garantie.capitalgarantie)) : ''
          }

          if (data.periodicite === 'M') {
            maPeriodicite = 'Mensuelle'
          } else if (data.periodicite === 'T') {
            maPeriodicite = 'Trimestrielle'
          } else if (data.periodicite === 'S') {
            maPeriodicite = 'Semestrielle'
          } else if (data.periodicite === 'A') {
            maPeriodicite = 'Annuelle'
          } else {
            maPeriodicite = 'Non defini'
          }

          body.push({
            garantie: garantie.monlibelle,
            option: option,
            primeGarantie: garantie.prime ? formatNumber(parseInt(garantie.prime)) : '',
            periodicite: maPeriodicite,
            totalprime: garantie.primetotal ? formatNumber(garantie.primetotal) : ''
          })
        })
      }

      return body
    }
  }

  doc.autoTable({
    startY: finalY + 3,
    head: headGaranties(),
    body: await bodyGaranties(),
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
    columnStyles: {
      totalprime: { textColor: 0, fontStyle: 'bold', halign: 'center', fontSize: '10' },
      periodicite: { textColor: 0, halign: 'left' },
      option: { textColor: 0, halign: 'left' },
    },
  });
  finalY = doc.previousAutoTable.finalY

  doc.setFontSize(12)
  //doc.addFont('ArialMS', 'Arial', 'bold ');
  //doc.setFontType('bold')

  if(data && data.fraisadhesion){
    doc.text('FRAIS D\'ADHESION :'  + formatNumber(data.fraisadhesion), 35, finalY + 5)
  }
  doc.text('PRIME TOTALE : ' + formatNumber(data.prime), 135, finalY + 5)


  doc.setLineWidth(0.1);
  doc.line(60, finalY + 8, 150, finalY + 8);
  if (data.codeproduit === 'PVRBNI') {
    doc.autoTable({
      startY: finalY + 10,
      head: [{ capitalSous: 'Montant de la rente', dureerente: 'Durée de service de la rente(mois)', dateeffet: "Date d'effet", dure: 'Durée du contrat', echeance: 'Echéance' }],
      body: [[`${data.montantrente ? formatNumber(parseInt(data.montantrente)) : ""}`, `${data.dureerente ? data.dureerente : ""}`, `${data.dateeffet ? dayjs(data.dateeffet).format('DD/MM/YYYY') : ""}`, `${data.duree ? data.duree : ""}`, `${(data.duree && data.duree) ? dayjs(data.dateeffet).add(data.duree, 'year').format('DD/MM/YYYY') : ""}`]],
      showHead: true,
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
      bodyStyles: { fillColor: [255, 255, 255] },
      headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
      columnStyles: {
        capitalSous: { halign: 'center', valign: 'center' },
        dureerente: { halign: 'center', valign: 'center' },
        dateeffet: { halign: 'center', valign: 'center' },
        dure: { halign: 'center', valign: 'center' },
        echeance: { halign: 'center', valign: 'center' },
      },
    });
  } else if (data.codeproduit === 'YKV_2004' || data.codeproduit === 'YKL_2004' || data.codeproduit === 'YKF_2004') {
    let moption = ""
    let detailOption = ''
    switch (data.codeproduit) {
      case 'YKV_2004': case 'YKL_2004':
        if (parseInt(data.capital) === 430000) {
          moption = "HONNEUR"
          detailOption = "Service de base : \n    Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    100 000 FCFA \nForfait Cash:\n    30 000 FCFA"
        }
        if (parseInt(data.capital) === 750000) {
          moption = "ELITE"
          detailOption = "Service de base : \n    Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \nAllocation Cercueil-Transport: \n    300 000 FCFA \nForfait Cash:\n    100 000 FCFA"
        }
        if (parseInt(data.capital) === 1400000) {
          moption = "PRESTIGE"
          detailOption = "Service de base : \n    Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \nAllocation Cercueil-Transport: \n    500 000 FCFA \nForfait Cash:\n    500 000 FCFA"
        }
        break;

      default:
        if (parseInt(data.capital) === 430000) {
          moption = "HONNEUR"
          detailOption = "Service de base : \n    Adulte : Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \n    Enfant: Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    Adulte : 100 000 FCFA / Enfant : 75 000 FCFA\nForfait Cash:\n    Adulte : 30 000 FCFA / Enfant : 25 000 FCFA"
        }
        if (parseInt(data.capital) === 750000) {
          moption = "ELITE"
          detailOption = "Service de base : \n    Adulte : Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \n   Enfant: Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    Adulte : 300 000 FCFA / Enfant: 150 000 FCFA \nForfait Cash:\n    Adulte : 100 000 FCFA / Enfant : 50 000 FCFA"
        }
        if (parseInt(data.capital) === 1400000) {
          moption = "PRESTIGE"
          detailOption = "Service de base : \n    Adulte : Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \n    Enfant: Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    Adulte : 500 000 FCFA / Enfant: 250 000 FCFA \nForfait Cash:\n    Adulte : 500 000 FCFA / Enfant : 250 000 FCFA"
        }
        break;
    }



    doc.autoTable({
      startY: finalY + 10,
      head: [{ option: `Option ${moption}`, dateeffet: 'Date effet', dure: 'Durée', echeance: 'Echéance' }],
      body: [[`${detailOption}`, `${data.dateeffet ? dayjs(data.dateeffet).format('DD/MM/YYYY') : ""}`, `${data.duree ? data.duree : ""}`, `${(data.duree && data.duree) ? dayjs(data.dateeffet).add(data.duree, 'year').format('DD/MM/YYYY') : ""}`]],
      showHead: true,
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
      bodyStyles: { fillColor: [255, 255, 255] },
      headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
      columnStyles: {
        dateeffet: { halign: 'center', valign: 'center' },
        dure: { halign: 'center', valign: 'center' },
        echeance: { halign: 'center', valign: 'center' },
      },
    });
  } else {
    doc.autoTable({
      startY: finalY + 10,
      head: [{ capitalSous: 'Capital souscrit', dateeffet: 'Date effet', dure: 'Durée', echeance: 'Echéance' }],
      body: [[`${data.capital ? formatNumber(parseInt(data.capital)) : ""}`, `${data.dateeffet ? dayjs(data.dateeffet).format('DD/MM/YYYY') : ""}`, `${data.duree ? data.duree : ""}`, `${(data.duree && data.duree) ? dayjs(data.dateeffet).add(data.duree, 'year').format('DD/MM/YYYY') : ""}`]],
      showHead: true,
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
      bodyStyles: { fillColor: [255, 255, 255] },
      headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
      columnStyles: {
        capitalSous: { halign: 'center', valign: 'center' },
        dateeffet: { halign: 'center', valign: 'center' },
        dure: { halign: 'center', valign: 'center' },
        echeance: { halign: 'center', valign: 'center' },
      },
    });
  }

if(data.codeproduit ==='PVRPRE'){
  //TO DO SIMULATION
}

  //paiement des PRIMES
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      [{ content: 'PAIEMENT DES PRIMES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle', valeur: 'Valeur', libelle: 'Libelle', valeur: 'Valeur' }],
    body: [
      ['Mode de paiement : ', data.modepaiement, 'Organisme :', data.organisme],
      ['Agence :', data.agence, 'N° Compte', data.numerocompte]
    ],
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', halign: 'left' },
    showHead: false,
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255], cellPadding: 0.5 },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },

      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },

      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },

      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],
  })


  finalY = doc.previousAutoTable.finalY
  doc.setFontSize(9)
  //doc.addFont('ArialMS', 'Arial', 'bold ');
  //doc.setFontType('bold')
  doc.text(15, finalY + 10, 'Fait à .................................................... le ' + new Date().toLocaleDateString())

  doc.text(20, finalY + 20, ` YAKO AFRICA                                               ADHERENT                                                                 ${(branche === 'BANKASS') ? partenaire.toUpperCase() : 'L\'ASSURE(E)'}`)
  doc.addImage(signature, "PNG", 15, finalY + 25, 40, 20)
  doc.addPage();
 
  let cgu = new Image()
  if (data.codeproduit === "YKV_2004") {
    cgu.src = require('assets/images/cg_ykv.png')
    doc.addImage(cgu, "PNG", 0, 0, 220, 310)
  }

  if (data.codeproduit === "YKL_2004") {
    cgu.src = require('assets/images/cg_ykl.png')
    doc.addImage(cgu, "PNG", 0, 0, 220, 310)
  }

  if (data.codeproduit === "YKF_2004") {
    cgu.src = require('assets/images/cg_ykf2004.png')
    doc.addImage(cgu, "PNG", 0, 0, 220, 310)
  }

  if (data.codeproduit === "PFA_BNI") {
    cgu.src = require('assets/images/cgu_pfa_bni.png')
    doc.addImage(cgu, "PNG", 10, 10)
  }

  if (data.codeproduit === "PVRBNI") {
    cgu.src = require('assets/images/cg_pvr.png')
    doc.addImage(cgu, "PNG", 0, 0, 220, 330)
  }


  doc.output('dataurlnewwindow');
}  