import React, { useState } from 'react'
import MaterialTable,{MTableToolbar} from 'material-table'
import { makeStyles } from '@material-ui/styles'
import { 
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Paper
 } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { connect, useDispatch, useSelector } from 'react-redux'
import ImageIcon from '@material-ui/icons/Image';
import { Field, reduxForm, change, registerField, getFormValues, reset, untouch } from 'redux-form';
import { green } from '@material-ui/core/colors';


const useStyle = makeStyles(theme=>({
  root:{

  },
  bcontainer:{
    paddingTop:10,
    backgroundColor:'transparent',
    borderRadius:10
  },
  bhead:{
    padding:10
  },
}))


function StepConventions(props) {
  const {handleNext,handleBack,steps,activeStep}=props
  const classes = useStyle()
  const dispatch =useDispatch()
  const handleCheckedProduit =(myrow)=>{
    setSelectedRow(myrow)
    if (!props.hasOwnProperty('produit')) {
        dispatch(registerField("adForm", 'produit', 'Field'));
    }
    props.updateField('produit', myrow)
    handleNext()
  }
  let produits = useSelector(state => state.api.produits);
  const [selectedRow, setSelectedRow] =useState(null)
  const [produitData, setProduitData] = React.useState({
    columns: [
        {
            title: 'code produit',
            field: 'codeProduit',
            cellStyle: { fontSize: 30, padding: 0, color: '#000', fontWeight: 'bold' },
            render: rowData => <List className={classes.list}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar variant="square" sizes={50}>
                            <ImageIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${rowData.MonLibelle} - ${rowData['Formule'] || ""}`} secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                                display='block'
                            >
                                {rowData.DureCotisationAns ? `Durée ${rowData.DureCotisationAns}` : ""}
                            </Typography>
                        </React.Fragment>
                    } />
                    <ListItemSecondaryAction>
                        <IconButton onClick={() => handleCheckedProduit(rowData)} style={{ backgroundColor: green[100], height: '100%', width: '100%' }} edge="end" aria-label="suivant">
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>,
        },
        { title: 'Libelle', field: 'MonLibelle', hidden: true },
        { title: 'Durée cotisation', field: 'DureeCotisationAns', type: 'numeric', hidden: true },
        { title: 'Durée souscription', field: 'DureeSouscriptionAnnee', type: 'numeric', hidden: true },
        { title: 'Type', field: 'CodeProduitNature', hidden: true },
    ]
  });

  const handleClientSelect = (event, myrow) => {
    setSelectedRow(myrow)
  }
  const customFilter = (columFilter, tableFilter, valueSeach) => {
    const isproduit = (item) => {
        let result = false
        for (const key of columFilter) {
            if (item[key]) {
                if (item[key].toUpperCase().indexOf(valueSeach.toUpperCase()) !== -1) {
                    result = true
                    break;
                } else {
                    result = false
                }
            } else {
                result = false
            }
        }
        return result
    }
    return tableFilter.filter(isproduit)
  }

  return (
    <div>
      <Paper className={classes.bcontainer} variant="outlined">
            <div className={classes.bhead}>
                  <Typography variant='h5'>Veuillez selectionnez une convention/produits</Typography>
            </div>

            <MaterialTable
                title={null}
                columns={produitData.columns}
                data={query => new Promise((resolve, reject) => {
                    const columnSearch = ['CodeProduit', 'MonLibelle', 'DureeCotisationAns', 'DureeSouscriptionAnnee', 'CodeProduitNature']
                    resolve({
                        data: customFilter(columnSearch, produits, query.search)
                    })
                })}
                onRowClick={((evt, selectedRow) => handleClientSelect(evt, selectedRow))}
                components={{
                    Toolbar: props => (
                        <div style={{ backgroundColor: '#e8eaf5',width:'100%' }}>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Rechercher',
                        searchTooltip: 'Rechercher'
                    },
                    body: {
                        emptyDataSourceMessage: 'Aucune donnée trouvée !'
                    },
                    pagination: {
                        labelRowsSelect: 'Ligne(s)',
                        labelDisplayedRows: '{count} sur {from}-{to}',
                        firstTooltip: 'Prémière page',
                        previousTooltip: 'Précédent',
                        nextTooltip: 'Suivant',
                        lastTooltip: 'Dernière page'
                    }
                }}
                options={{
                    search: true,
                    searchFieldAlignment: "left",
                    paging:(produits.length>5),
                    toolbar:(produits.length>5),
                    pageSize: 5,
                    pageSizeOptions: [10, 20, 30, 40, 50, 100],
                    searchFieldStyle: {
                        fontSize: 18,
                        width: '100%',
                        height: 50,
                    },
                    header: false,
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#FFF1EB' : '#FFF'
                    })
                }}
            />
        </Paper>
    </div>
  )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    updateField
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state),
    ui: state.UI
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
})(StepConventions))

