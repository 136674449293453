import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  MARK_NOTIFICATIONS_READ,
  SET_USER_DETAIL,
  SET_LAYOUT,
  SET_PRIVILEGES,
} from '../types';
import axios from 'axios';
import {
  getVille,
  getProfession,
  getCivilite,
  getSociete, getProduit, getSitMatiromoniale, selectPropositionByUser
} from 'redux/actions/apiActions'
import moment from "moment";
import "moment/locale/fr";


export const loginUser = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  const { login, password } = userData
  console.log( { login, password });
  return axios
    .post('/login', { login, password })
    .then((res) => {
      console.log(res);
      if (res) {
        if (res.status == 200 || res.status == 201) {
          setAuthorizationHeader(res.data.token);
          dispatch({ type: SET_USER, payload: res.data });
          dispatch({ type: CLEAR_ERRORS });
          dispatch(getPrivileges())
          dispatch(getVille());
          dispatch(getProfession());
          dispatch(getCivilite());
          dispatch(getSociete());
          dispatch(getProduit(res.data.branche));
          dispatch(getSitMatiromoniale());
          let layout = 'admin'
          if (res.data.branche) {
            layout = res.data.branche.toLowerCase()
          }
          dispatch({ type: SET_LAYOUT, payload: layout })
          return layout
        } else {
          return false
        }
      }
    }).catch((err) => {
      console.log(err);
      if (err.response && err.response.hasOwnProperty('data')) {
        dispatch({ type: SET_ERRORS, payload: err.response.data });
        return false
      }
      return false
    });
};

export const getPrivileges = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  return axios
    .get('/privileges-user')
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: SET_PRIVILEGES, payload: res.data })
        return res.data
      } else {
        dispatch({ type: CLEAR_ERRORS });
        return false
      }
    }).catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err
      });
      return false
    });
}
export const getPrivilegesModule = (codeModule) => {
  let url = codeModule ? `/privileges-user/${codeModule}` : `/privileges-user`
  return axios
    .get(url)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    })
    .catch((err) => {
      console.log(err);
      return false
    });
}
export const signupUser = (newUserData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post('/signup', newUserData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      history.push('/');
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};

//changer de mot de passe
export const changePassword = (dataUser) => (dispatch) => {
  if (!dataUser) return
  return axios.post('/change-user-password', dataUser).then(res => {
    if (res.status === 200 || res.status === 201) {
      dispatch({ type: CLEAR_ERRORS })
      return res.data
    } else {
      dispatch({ type: SET_ERRORS, payload: res.data })
      return false
    }
  }).catch(err => {
    dispatch({ type: SET_ERRORS, payload: err.response.data })
    console.log(err);
    return false
  })
}

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('FBIdToken');
  localStorage.clear()
  sessionStorage.clear()
  delete axios.defaults.headers.common['Authorization'];
  dispatch({ type: SET_UNAUTHENTICATED });
};

export const getUserData = (params = null) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post('/details-users')
    .then((res) => {
      dispatch({
        type: SET_USER_DETAIL,
        payload: res.data
      });
    })
    .catch((err) => console.log(err));
};

export const getBrancheCodeProduit = (codeProduit) => {
  return axios
    .get(`/produit-branche/${codeProduit}`)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};

export const uploadImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post('/user/image', formData)
    .then(() => {
      //dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const getUserByToken = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios.post('/auto-login').then((res) => {
   
    if (res.status === 200 || res.status === 201) {
      dispatch({ type: SET_USER, payload: res.data?.data });
      dispatch({ type: CLEAR_ERRORS });
      dispatch(getPrivileges())
      dispatch(getProduit(res.data?.data.branche));
      dispatch(getVille());
      dispatch(getProfession());
      dispatch(getCivilite());
      dispatch(getSociete());
      dispatch(getSitMatiromoniale());

      return res.data;
    } else {
      dispatch({ type: SET_ERRORS, payload: { "erreur": "Une erreur inconnu s'est produite" } });
      return false;
    }
  }).catch((err) => {
    console.log(err.response);
    if (err.response && err.response.hasOwnProperty('data')) {
      dispatch({ type: SET_ERRORS, payload: err.response.data });
    }
    return false;
  });
};

export const editUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post('/user', userDetails)
    .then(() => {
      //dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const markNotificationsRead = (notificationIds) => (dispatch) => {
  axios
    .post('/notifications', notificationIds)
    .then((res) => {
      dispatch({
        type: MARK_NOTIFICATIONS_READ
      });
    })
    .catch((err) => console.log(err));
};

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem('FBIdToken', FBIdToken);
  axios.defaults.headers.common['Authorization'] = FBIdToken;
};

export const ageAssure = (madateref) => {
  const now = moment(new Date(), 'YYYY')
  const end = moment(madateref, 'YYYY');
  const duration = moment.duration(now.diff(end));
  const age = duration.asYears();
  return age.toString().split('.')[0]
}


export const ageBorne = (dateDebut, dateFin) => {
  const now = moment(dateFin, 'YYYY')
  const end = moment(dateDebut, 'YYYY');
  const duration = moment.duration(now.diff(end));
  const age = duration.asYears();
  return age.toString().split('.')[0]
}



//opération agent

//liste des commission
export const getBulletinComList = (data)=>{
  if(!data){ return false}
  return axios
  .post('/bulletin-list-bis', data)
  .then((res) => {
    if(res.status ===200 || res.status ===201){
      return res.data
    }else{
      return false
    }
  })
  .catch((err) => {
    console.log(err)
    return false
  });
}

//detail bulletin de commission
export const getBulletinComDetail =(data)=>{
  if(!data){ return false}
  return axios
  .post('/bulletin-print-bis', data)
  .then((res) => {
    if(res.status ===200 || res.status ===201){
      return res.data
    }else{
      return false
    }
  })
  .catch((err) => {
    console.log(err)
    return false
  });
}