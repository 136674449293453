import React,{useState,useEffect} from 'react'
import {connect} from 'react-redux'
import logo from "assets/images/logoYnovWhite.png";
import logo2 from "assets/images/icone.png";
import { IconButton, Tooltip } from '@material-ui/core';
import {Link, Switch, Route, Redirect } from "react-router-dom";
import {courtage,bankass,com} from "routes";
import ProductionContainer from "pages/Courtier/Production/ProductionContainer";
import FooterNew from 'components/Footer/FooterNew';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks";
import {selectPartenaire} from 'redux/actions/settingsAction'
import PretHome from "pages/Banking/Prets/PretHome"
import ChangePassword from 'pages/Dashboard/ChangePassword'
const MasterPartenaire = (props) => {
  let currentRoute
  const {ui: { layoutRoot },user:{credentials}} =props
  if (layoutRoot === 'bankass') {currentRoute =bankass }
  if (layoutRoot === 'producteur') {currentRoute =[]}
  if (layoutRoot === 'courtage') {currentRoute=courtage}
  if (layoutRoot === 'entreprise') {currentRoute=[]}
  if (layoutRoot === 'client') {currentRoute=[]}
  if (layoutRoot === 'com') {currentRoute=com}
  if (layoutRoot === 'settings') {currentRoute=[]}
  if (layoutRoot === 'medecin') {currentRoute=[]}

  let newRoute = currentRoute&&currentRoute.filter(item=>item.partenaire.includes(credentials.codepartenaire))

    const [open,setOpen] = useState(true)
    const [partenaire,setPartenaire] =useState(null)
    const handlePartenaire =()=>{
      if(credentials){
        selectPartenaire(credentials?.codepartenaire).then(res=>{
          if(res){setPartenaire(res)}
        })
      }
    }
    useEffect(()=>{
      handlePartenaire()
    },[])

  return (
    <div className='bg-green-900 px-4 min-h-[100vh] grid grid-rows-8'>

          <div className='flex p-2 row-span-1'>
              <div className={open ? "w-72" : "w-20 flex items-center justify-center p-1"}>
                {open ? <img src={logo} className="cursor-pointer ml-4 w-32"/> : <img src={logo2} className="cursor-pointer w-10 h-10 p-1 rounded-full bg-white"/>}
              </div>

              <div className="hidden md:flex flex w-full items-center justify-between">
                <IconButton className="left-0" onClick={()=>setOpen(!open)}>
                    <FormatAlignLeftIcon className={`text-white ${!open && "transform rotate-180"}`}/>
                </IconButton>
                  <div className="flex gap-1">
                      <AdminNavbarLinks color='white' />
                  </div>
              </div>
          </div>

        <div className={`flex row-span-6 gap-4`}>
            
            <div className={`${open ? "w-72" : "w-20"} hidden md:flex flex flex-col h-[100vh] gap-2`}>
              <div className={`flex flex-col bg-[#3d8a415a] rounded-2xl ${!open&&"hidden"}`}>
                {partenaire ? <img className='flex object-cover rounded-lg' src={require(`assets/images/${(partenaire && partenaire.code!=='')?partenaire.code+'.jpg':'logo_yako.jpg'}`)} /> : (<img className='flex object-cover rounded-lg' src={require(`assets/images/logo_yako.jpg`)} />)
                }
              </div>
              <div className="hidden md:flex flex flex-col gap-4 my-2 p-3 rounded-2xl bg-[#3d8a415a] outline-1 h-full">
                {newRoute.map((r,idx)=>(
                <Link key={idx} to={`/${layoutRoot}${r['path']}`} className={`h-9 w-full group relative gap-4 pl-4 ${open&&"border"} outline-1 bg-transparent border-slate-50 rounded-full flex items-center justify-center`}>
                      <r.icon className={`${open ? "absolute h-9 w-9" : "relative w-11 h-11"} group-hover:bg-secondary-main group-hover:text-white group-hover:transform scale-50  left-[-10px] bg-slate-100 rounded-full p-1 text-slate-400 origin-left`}/>
                      <span className={`my-2 text-slate-100  ${!open && "hidden"}`}>{r['name']}</span>
                 
                  </Link>))}
              </div>
            </div>
            
            <main className='bg-white rounded-xl mt-2 flex-1'>
                 {newRoute&&<Switch>
                    {newRoute.map((prop, key) => (<Route path={prop.layout + prop.path} component={prop.component} key={key}/>))}
                    <Route path={`/${layoutRoot}/modification-mot-passe`} component={ChangePassword} name='Modification du mot de passe' />
                    <Route path={`/${layoutRoot}`} component={PretHome} name='Gestion des prestations' />
                    <Route path={`/${layoutRoot}/modification-proposition`} component={ProductionContainer} name='Modification proposition' />
                    <Redirect from={`/${layoutRoot}/prets`} to={`/${layoutRoot}/epret`} />
                    <Redirect from={`/${layoutRoot}`} to={`/${layoutRoot}/tableau-de-bord`} />
                  </Switch>}
            </main>
        </div>

        <div className='flex justify-center row-span-1 items-center'><FooterNew /></div>   
    </div>
  )
}
const mapStateToProps = (state) => ({
    ui: state.UI,
    user: state.user
  });
  
  const mapActionsToProps = {}
export default connect(mapStateToProps, mapActionsToProps)(MasterPartenaire)